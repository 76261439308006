import { Injectable } from '@angular/core';
import { AuthService } from '../AuthService/auth.service';
import { HttpClient } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { IGenericResponse, IGenericResponseObject } from '../../Model/Generic/generic-response';
import { environment } from 'src/environments/environment.prod';
import { ITeacherPurchasedLessons, ITeacherPurchasedSubjects, ITotalFinance } from '../../Model/teacher/teacher-finance';
import { ITPagination } from '../../Model/Pagination/pagination';


@Injectable({
  providedIn: 'root'
})
export class TeacherFinanceService {
  constructor(private http: HttpClient,private auth:AuthService ) {}


  public GetFinanceForTeacher( ): Observable<ITotalFinance> {
    return this.http.get<ITotalFinance>(`${environment.Server_URL}Teacher/TeacherFinance`).pipe(
        map((response: any) => {
            return response.data;
        })
    );
  }
  public TeacherLessonsForSubjectGroup(TeacherLessonSessionId:number): Observable<ITeacherPurchasedLessons[]> {
    return this.http.get<ITotalFinance>(`${environment.Server_URL}Teacher/TeacherLessonsForSubjectGroup?teacherLessonSessionId=${TeacherLessonSessionId}`).pipe(
        map((response: any) => {
            return response.data;
        })
    );
  }

  public GetTeacherPurchasedLessons(data:any): Observable<ITPagination<ITeacherPurchasedLessons>> {
    return this.http.post<ITPagination<ITeacherPurchasedLessons>>(`${environment.Server_URL}Teacher/TeacherPurchasedLessons`,data).pipe(
        map((response: any) => {
            return response.data;
        })
    );
  }
  public GetTeacherPurchasedSubjects(data:any): Observable<ITPagination<ITeacherPurchasedSubjects>> {
    return this.http.post<ITPagination<ITeacherPurchasedSubjects>>(`${environment.Server_URL}Teacher/TeacherPurchasedSubjects`,data).pipe(
        map((response: any) => {
            return response.data;
        })
    );
  }
}

