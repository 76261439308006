import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NavTabsComponent } from 'src/app/Authentication/tabs/nav-tabs/nav-tabs.component';

import { SeoService } from 'src/app/shared/Apis/appsettings/seo.service';
import { LayoutService } from 'src/app/shared/core/layout.service/layout.service';
import { ITotalFinance } from 'src/app/shared/Model/teacher/teacher-finance';
import { TeacherFinanceService } from 'src/app/shared/Apis/Teacher/teacher-finance.service';
import { NgxPaginationModule } from 'ngx-pagination';
import { TeacherPurchasedLessonsComponent } from './teacher-purchased-lessons/teacher-purchased-lessons.component';
import { TeacherPurchasedSubjectsComponent } from './teacher-purchased-subjects/teacher-purchased-subjects.component';
import { AuthService } from 'src/app/shared/Apis/AuthService/auth.service';
@Component({
  selector: 'app-Finance',
  templateUrl: './Finance.component.html',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    TooltipModule,
    //Component
    TeacherPurchasedLessonsComponent,
    TeacherPurchasedSubjectsComponent,
  ],
  styleUrls: ['./Finance.component.scss'],
})
export class TeacherFinanceComponent implements OnInit {
  Finance: any;
  TotalFinance!: ITotalFinance;

  constructor(
    private TeacherFinanceService: TeacherFinanceService,
    private seoService: SeoService,
    private layoutService: LayoutService, private AuthService:AuthService
  ) {
    // this.Controller = this._SSRService.Location(2);
  }

  ngOnInit() {
    this.seo();
    this.GetFinance();
    this.GetEgyptDateTime();
  }

  GetFinance() {
    this.TeacherFinanceService.GetFinanceForTeacher().subscribe((res) => {
      this.TotalFinance = res;
    });
  }

  private seo() {
    const lang = this.layoutService.config.langu;
    this.seoService.loadTranslations(lang).subscribe((translations) => {
      this.seoService.setTitle(translations.Finance.header.Finance);
      this.seoService.setHostUrlIndex();
      this.seoService.setMetaDescription(
        translations.Home.header.meta_description
      );
      this.seoService.setMetaKeywords(
        this.seoService.generateKeywords(
          translations.Home.header.meta_description
        )
      );
    });
  }


  serverTime!: any;

  private GetEgyptDateTime() {
    this.AuthService.GetEgyptDateTime().subscribe({
      next: (data: any) => {
        console.log("data" ,data );
        try {
          // const parsedDate = new Date("2024-12-18T13:27"); // Convert the string to a Date object
          // const parsedDate = new Date(data); // Convert the string to a Date object
          this.serverTime =  data
          this.serverTime=     this.serverTime.replace(/[T]/g, '-');

          console.log( this.serverTime)


        } catch (error) {
          console.error('Failed to parse date:', error);
        }
      },
      error: (error) => {
        console.error('Error fetching date:', error);
      }
    });
  }
}
