import { Component } from '@angular/core';
import { CommonModule, formatDate } from '@angular/common';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-base',
  template: '',
  standalone: true,
  imports: [CommonModule],
})
export class BaseComponent {
  pageSizeOptions: number[] = [1, 10, 50, 100];
  pageIndex = 1;
  totalRecords = 0;
  pageNumber = 1;
  pager: any = {
    maxResultCount: 25,
    skipCount: 0,
  };

  // Date and time
  expirationDateNow = formatDate(new Date(), 'yyyy-MM-dd', 'en-US');
  expiration = formatDate(new Date().setUTCHours(24), 'yyyy-MM-dd', 'en-US');
  licenseIssue = formatDate(new Date().setUTCHours(-24), 'yyyy-MM-dd', 'en-US');
  DateTime = formatDate(
    new Date().setUTCHours(24),
    "yyyy-MM-dd'T'HH:mm",
    'en-US'
  );

  getDateUtc(licenseExpirationDate: any) {
    const date1: Date = new Date(
      formatDate(licenseExpirationDate, 'yyyy-dd-MM', 'en-US')
    );
    const currentDate: Date = new Date();

    if (date1.getTime() < currentDate.getTime()) {
      return false;
    } else {
      return true;
    }
  }

  dateConverterPlus18(): string {
    const date = new Date().getUTCFullYear() - 18;
    const maxDate = new Date().setUTCFullYear(date);
    return formatDate(maxDate, 'yyyy-MM-dd', 'en-US');
  }

  dateConverterPlus18s(): string {
    const today = new Date();
    const eighteenYearsAgo = new Date(
      today.getFullYear() - 50,
      today.getMonth(),
      today.getDate()
    );
    return eighteenYearsAgo.toISOString().slice(0, 10);
  }

  public whitespaceValidator(control: FormControl) {
    const regex = /^\S.*\S$/;
    const isValid = regex.test(control.value);
    return isValid ? null : { whitespace: true };
  }

  public DateUTCPlusHours: string = this.DateUTCPlus(JSON.parse(localStorage.getItem('hoursPlusUtc') ?? '0'));

  private DateUTCPlus(hoursToAdd: number = 0): string {
    // الحصول على التوقيت الحالي UTC
    let currentDate = new Date();
    console.log('Current Date:', currentDate);

    // تحويل الوقت الحالي إلى توقيت UTC بدون تغيير المنطقة الزمنية
    let utcDate = new Date(currentDate.getTime() + (currentDate.getTimezoneOffset() * 60000));
    console.log('UTC Date:', utcDate);

    // إضافة الساعات المطلوبة
    utcDate.setUTCHours(utcDate.getUTCHours() + hoursToAdd);
    console.log('Updated UTC Date:', utcDate);

    // تحويل التاريخ إلى صيغة ISO
    return utcDate.toISOString();
}

  private DateUTCPlus_(hoursToAdd: number = 0): string {

        let currentDate = new Date();
        console.log(currentDate)
        // تحويل الوقت الحالي إلى توقيت UTC
        let utcDate = new Date(currentDate.toUTCString());
        console.log(utcDate)
        // إضافة الساعات المطلوبة
        utcDate.setHours(utcDate.getHours() + hoursToAdd);
        console.log(utcDate)
        // تحويل التاريخ إلى صيغة ISO
        return utcDate.toISOString();


  }

}
