import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { LayoutService } from 'src/app/shared/core/layout.service/layout.service';
import { environment } from 'src/environments/environment';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-get-egypt-date-time',
  standalone: true,
  imports: [CommonModule,TranslateModule],
  templateUrl: './get-egypt-date-time.component.html',
  styleUrls: ['./get-egypt-date-time.component.scss']
})
export class GetEgyptDateTimeComponent {
  @Input() class :string=''
  @Output() setegyptDateTime = new EventEmitter<any>();
constructor(private http: HttpClient,  public layoutService: LayoutService) {
  this.GetEgyptDateTime()
}

egyptDateTime!: any;

private GetEgyptDateTime() {
  this.http.get(`${environment.Server_URL}Settings/GetEgyptDateTime` ).subscribe({
    next: (data: any) => {
      console.log("data" ,data );
      try {
        // const parsedDate = new Date("2024-12-18T13:27"); // Convert the string to a Date object
        // const parsedDate = new Date(data); // Convert the string to a Date object
        this.egyptDateTime =  data
        this.egyptDateTime=     this.egyptDateTime.replace(/[T]/g, '-');
        this.setegyptDateTime.emit(data); // Emit the Date object
      } catch (error) {
        console.error('Failed to parse date:', error);
      }
    },
    error: (error) => {
      console.error('Error fetching date:', error);
    }
  });
}
// private GetEgyptDateTime() {
//   this.http.get(`${environment.Server_URL}Settings/GetEgyptDateTime`).subscribe({
//     next: (data: any) => {
//       console.log("data", data);
//       try {
//         // تحويل التاريخ المستلم إلى كائن Date
//         const parsedDate = new Date(data);

//         // تعيين الوقت بدون تعديل
//         this.egyptDateTime = parsedDate.toISOString();

//         // نشر التوقيت المعدل
//         this.setegyptDateTime.emit(parsedDate);
//       } catch (error) {
//         console.error('Failed to parse date:', error);
//       }
//     },
//     error: (error) => {
//       console.error('Error fetching date:', error);
//     }
//   });
// }


}
