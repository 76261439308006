import { Component, OnInit, TemplateRef } from '@angular/core';
import { CommonModule, NgIf } from '@angular/common';
import { addMinutes } from 'date-fns';

import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ActivatedRoute, Router } from '@angular/router';

import { BsModalRef, BsModalService, ModalModule } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { LookupService } from 'src/app/shared/Apis/lookup/lookup.service';
import { IStatus } from 'src/app/shared/Model/Country/country';
import { ManageMyScheduleService } from 'src/app/shared/Apis/Teacher/Schedule/manage-my-schedule.service';
import { ISchedule } from 'src/app/shared/Model/teacher/schedule';
import { NgSelectModule } from '@ng-select/ng-select';
import { BaseComponent } from 'src/app/shared/components/Base/base.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { SeoService } from 'src/app/shared/Apis/appsettings/seo.service';
import { LayoutService } from 'src/app/shared/core/layout.service/layout.service';
import { GetEgyptDateTimeComponent } from 'src/app/layout/footer/get-egypt-date-time/get-egypt-date-time.component';
import { AuthService } from 'src/app/shared/Apis/AuthService/auth.service';
@Component({
  selector: 'app-manage-my-schedule',
  standalone: true,
  imports: [
    CommonModule,
    NgIf,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    TooltipModule,
    ModalModule,
    NgSelectModule,
    MatExpansionModule
  ],
  templateUrl: './manage-my-schedule.component.html',
  styleUrls: ['./manage-my-schedule.component.scss'],
})
export class ManageMyScheduleComponent extends BaseComponent {
  protected insertForm!: FormGroup;
  public panelOpenState  = false;
  public panelOpenStateList  = true;

  submit: boolean = true;
  protected TableDeletMyScheduleID!: number; // IStatus ==id , name => dayes of week;
  protected daysOfWeek!: IStatus[]; // IStatus ==id , name => dayes of week;
  protected manageSchedule!: ISchedule[];
  modalRef?: BsModalRef;
  protected SearchForm!: FormGroup;

  constructor(
    private _ManageMySchedule: ManageMyScheduleService,
    private _Lookup: LookupService,
     private _fb: FormBuilder,
    private seoService: SeoService,
    private layoutService: LayoutService,
    private modalService: BsModalService,    private AuthService: AuthService
  ) {
    super();
  }
  ngOnInit(): void {
    this.initiate();
    this.initiateSearch();
    this.GetDays();
    this.GetManageMySchedule({});
    this.seo();

    this.GetEgyptDateTime();



  }

  private initiate(row?: any) {
    this.insertForm = this._fb.group({
      dayId: [row?.dayId || '', Validators.required],
      fromStartDate: [row?.fromStartDate || '', Validators.required],
      toEndDate: [row?.toEndDate || '', Validators.required],
      fromTime: [row?.fromTime || '', Validators.required],
      toTime: [row?.toTime || '', Validators.required],
    });
  }
  get fc() {
    return this.insertForm.controls;
  }

  protected get fcSearch() {
    return this.SearchForm.controls;
  }

  private initiateSearch() {
    this.SearchForm = this._fb.group({
      dayId: [''],
      fromStartDate: [''],
      toEndDate: '',
    });
  }
  protected onSubmit() {
    this.submit = false;

    if (this.insertForm.valid) {
      // Perform the submit logic here


      const fromStartDate = addMinutes(
        new Date(this.fc['fromStartDate'].value),
        this.convertTimeToMinutes(this.fc['fromTime'].value) ?? 0
      );

      const toEndDate = addMinutes(
        new Date(this.fc['toEndDate'].value),
        this.convertTimeToMinutes(this.fc['toTime'].value) ?? 0
      );

      // Now you can compare `fromStartDate` and `toEndDate`
      if (fromStartDate > toEndDate) {
        Swal.fire({
          icon: 'warning',
          title: 'warning',
          text: 'Start Date Should Be Before To End Date ',
        });
        this.submit = true;
      } else this.insertData();
    } else {
    this.submit = true;
      this.insertForm.markAllAsTouched();
    }
  }
  private insertData() {
    this.submit = false;
    // this.insertForm.patchValue({
    //   fromTime:this.fc['fromTime'].value+":00",
    //   toTime:this.fc['toTime'].value+":00"
    // })
    this._ManageMySchedule.AddTeacherSchedule(this.insertForm.value).subscribe(
      (res) => {
        if (res.success == true) {
          this.SearchForm.reset();

          this.GetManageMySchedule({});
          // Display a success message
          Swal.fire({
            icon: 'success',
            title: 'success',
            text: 'Add Succsessfully',
          });
          this.insertForm.reset();
        }
        this.submit = true;
      },
      (err) => {
        this.submit = true;
      }
    );
  }

  private GetDays() {
    this._Lookup.GetDays().subscribe((days) => {
      this.daysOfWeek = days;
    });
  }
  protected DeleteTeacherSchedule(id: number) {
    this.submit = false;

    this._ManageMySchedule.DeleteTeacherSchedule(id).subscribe((res) => {
      if (res) {
        this.SearchForm.reset()
        this.GetManageMySchedule({});
        this.modalRef?.hide();
        // Display a success message
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Deleted successfully',
        });
      }
      this.submit = true;
    },
    (err) => {
      this.submit = true;
    }
  );
}

  protected Clear() {
    this.insertForm.reset();
    // this.fc['materialUrl'].enable();
  }

  protected ChangeSearchInp() {
    this.GetManageMySchedule(this.SearchForm.getRawValue());
    // else this.GetMyLessonMaterials({ teacherLessonId: this.teacherLessonId });
  }
  protected openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  private GetManageMySchedule(_filter:any) {
    const filter = _filter;
    // Remove properties with value null
    Object.keys(filter).forEach(
      (key) =>
        (filter[key] === null && delete filter[key]) ||
        (filter[key] === '' && delete filter[key])
    );
    this._ManageMySchedule.GetManageMySchedule(filter).subscribe((manage) => {
      this.manageSchedule = manage;

    });
  }

  private convertTimeToMinutes(timeString: string): number | null {

    // Regular expression to match the "HH:mm:ss" format
    const timeRegex = /^(\d{2}):(\d{2})$/;

    // Check if the input time string matches the expected format
    const match = timeString.match(timeRegex);

    if (match) {
      // Extract the hour and minute components
      const hours = parseInt(match[1], 10);
      const minutes = parseInt(match[2], 10);

      // Calculate the total minutes
      const totalMinutes = hours * 60 + minutes;

      return totalMinutes;
    } else {
      // Return null if the input format is not valid
      console.error('Invalid time format. Expected "HH:mm"');
      return null;
    }
  }

    //  seo ssr
private seo(){
  // this.seoService.setMetaImage( 'assets/images/MrS-Cool/setting.png'   );
  const lang = this.layoutService.config.langu;
  this.seoService.loadTranslations(lang).subscribe(translations => {
    this.seoService.setTitle(translations.ManageMySchedule.header.Title2);
    this.seoService.setHostUrlIndex();
    this.seoService.setMetaDescription(translations.Home.header.meta_description)
    this.seoService.setMetaKeywords(this.seoService.generateKeywords(translations.Home.header.meta_description ))

  });

}
serverTime!: any;

private GetEgyptDateTime() {
  this.AuthService.GetEgyptDateTime().subscribe({
    next: (data: any) => {
      console.log('data', data);
      try {
        // const parsedDate = new Date("2024-12-18T13:27"); // Convert the string to a Date object
        // const parsedDate = new Date(data); // Convert the string to a Date object
        this.serverTime = data;
        this.serverTime = this.serverTime.replace(/[T]/g, '-');
        console.log(this.serverTime);
        this.fc['dateFrom'].setValue(this.serverTime.slice(0, 10));
        this.onSubmit();
      } catch (error) {
        console.error('Failed to parse date:', error);
      }
    },
    error: (error) => {
      console.error('Error fetching date:', error);
    },
  });
}
}
