import { Component, OnInit, TemplateRef } from '@angular/core';
import { CommonModule, NgIf } from '@angular/common';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { BsModalRef, BsModalService, ModalModule } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { environment as env } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { Clipboard } from '@angular/cdk/clipboard';
import { LookupService } from 'src/app/shared/Apis/lookup/lookup.service';
import { IStatus } from 'src/app/shared/Model/Country/country';
import {
  IGetTeacherLessonForList,
  ISubjectGroup,
  ITeacherSubjectAcademicSemesterYearForList,
} from 'src/app/shared/Model/teacher/subject-group';
import { SubjectGroupService } from 'src/app/shared/Apis/Teacher/Geoup/subject-group.service';
import { GroupScheduleComponent } from './group-schedule/group-schedule.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { BaseComponent } from 'src/app/shared/components/Base/base.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { SeoService } from 'src/app/shared/Apis/appsettings/seo.service';
import { LayoutService } from 'src/app/shared/core/layout.service/layout.service';
import { GroupForLessonService } from 'src/app/shared/Apis/Teacher/Geoup/group-for-lesson.service';
import { AddSessionModleComponent } from './add-session-modle/add-session-modle.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OnlyNumbersDirective } from 'src/app/shared/Directive/only-numbers.directive';
import { OnlyNumbersWithDoubleDirective } from 'src/app/shared/Directive/only-numbers-with-double.directive';
import { GetEgyptDateTimeComponent } from 'src/app/layout/footer/get-egypt-date-time/get-egypt-date-time.component';
import { AuthService } from 'src/app/shared/Apis/AuthService/auth.service';
@Component({
  selector: 'app-subject-group',
  standalone: true,
  imports: [
    CommonModule,

    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    OnlyNumbersDirective,
    TooltipModule,
    ModalModule,
    GroupScheduleComponent,
    NgSelectModule,
    MatExpansionModule,
    OnlyNumbersWithDoubleDirective,
    RouterLink,GetEgyptDateTimeComponent
  ],
  templateUrl: './subject-group.component.html',
  styleUrls: ['./subject-group.component.scss'],
})
export class SubjectGroupComponent extends BaseComponent implements OnInit {
  protected dynamicForm!: FormGroup;
  public panelOpenState = false;
  groupSessionCost: FormControl = new FormControl('', [
    Validators.required,
    Validators.min(0.1),
  ]);

  protected modalRef?: BsModalRef;
  protected submit: boolean = true;
  protected SearchForm!: FormGroup;
  protected TableDeleteGroupID!: number;
  protected daysOfWeek!: IStatus[]; // IStatus ==id , name => dayes of week;
  protected SubjectGroups!: ISubjectGroup[];
  protected SubjectGroupsIsReview!: ISubjectGroup;
  protected SubjectAcademicSemesterYearForList!: ITeacherSubjectAcademicSemesterYearForList[];
  protected IsReview: boolean = false;
  constructor(
    private _fb: FormBuilder,
    private _router: Router,
    private modalService: BsModalService,
    private clipboard: Clipboard,
    private _Lookup: LookupService,
    protected _SubjectGroup: SubjectGroupService,
    private seoService: SeoService,
    private translate: TranslateService,
    private layoutService: LayoutService,
    private _GroupForLessonService: GroupForLessonService,
    public modal: NgbModal, private AuthService:AuthService
  ) {
    super();
  }

  ngOnInit(): void {
    this.GetEgyptDateTime();
    this.seo();
    this.initiateForm();
    this.initiateSearch();
    this.GetDays();
    this.GetMySubjectSchedualGroup({});
    this.GetTeacherSubjectAcademicSemesterYearForList();
    this.addRow();
    window.scroll(0, 0);
  }

  private initiateForm(row?: any) {
    this.dynamicForm = this._fb.group({
      teacherSubjectAcademicSemesterYearId: ['', Validators.required],
      teacherSubjectAcademicSemesterYearName: ['', Validators.required],
      groupName: ['', Validators.required],
      groupCost: [''],
      startDate: ['', Validators.required],
      scheduleSlots: this._fb.array([]),
      teacherLessonList: this._fb.array([]),
    });
  }
  private initiateSearch(row?: any) {
    this.SearchForm = this._fb.group({
      teacherSubjectAcademicSemesterYearId: [],
      groupName: [''],
      startDate: '',
      endDate: '',
      statusId: '',
    });
  }
  addRowForlessons() {
    const row = this._fb.group({
      dayId: ['', Validators.required],
      timeFrom: ['', Validators.required],
    });

    this.teacherLessonList.push(row);
  }
  addRow() {
    const row = this._fb.group({
      dayId: ['', Validators.required],
      timeFrom: ['', Validators.required],
      // toTime: ['', Validators.required],
    });

    if (this.rows.length < 7) {
      this.rows.push(row);
    }
  }
  deleteRow(index: number) {
    this.rows.removeAt(index);
  }
  ClearRow(index: number) {
    // this.rows.at(index)  .patchValue({
    //   dayId: [null, Validators.required],
    //   timeFrom: ['', Validators.required],
    //   // toTime: [null],
    // });
    this.rows.at(index).reset();
  }
  get fc() {
    return this.dynamicForm.controls;
  }
  get rows() {
    return this.dynamicForm.get('scheduleSlots') as FormArray;
  }
  get teacherLessonList() {
    return this.dynamicForm.get('teacherLessonList') as FormArray;
  }

  protected onSubmit() {
     // Get the form value first
    const data = this.dynamicForm.value;

    // Filter out teacherLessonList entries where checkbox is false
    data.teacherLessonList = data.teacherLessonList.filter(
      (lesson: any) => lesson.checkbox === true
    );
    if (data.teacherLessonList.length <= 0) {
      Swal.fire({
        icon: 'warning',
        title: 'warning',
        text: this.translate.instant('choose_at_least_one_lesson'),
      });
      return;
    } else {
      data.groupCost = data.teacherLessonList.reduce(
        (total: number, lesson: any) => {
          return parseFloat(
            (total + this.groupSessionCost.value * lesson.count).toFixed(2)
          );
        },
        0
      );
    }

     if (this.dynamicForm.valid && this.groupSessionCost.valid) {
      // Perform the submit logic here
      // this.IsReview = true;
      this.GetReviewSubjectSchedualGroup(data);
      // this.fc['id'].value == null ? this.insertData() : this.UpdateSubject();
    } else {
      this.dynamicForm.markAllAsTouched();
      this.groupSessionCost.markAllAsTouched();
    }
  }

  Clear() {
    this.dynamicForm.reset();
    this.TeacherLessonForList = [];
    this.alertFlag = false;
  }

  ChangeSearchInp() {
    this.GetMySubjectSchedualGroup(this.SearchForm.getRawValue());
  }

  protected DeleteSubjectTeacherScheduleGroup(id: number) {
    this._SubjectGroup.DeleteSubjectTeacherScheduleGroup(id).subscribe(
      (res) => {
        if (res.success) {
          Swal.fire({
            icon: 'success',
            title: 'success',
            text: 'Deleted successfully',
          });
          this.SearchForm.reset();
          this.modalRef?.hide();
          this.GetMySubjectSchedualGroup({});
        }
      },
      (err) => {
        this.submit = true;
      }
    );
  }
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  /**
   * Copies the provided text into the user's clipboard.
   *
   * @param text The string to copy.
   * @returns Whether the operation was successful.
   */
  copyText(item?: any) {
    const textToCopy = 'Hello, world!'; // Set the text you want to copy here
    this.clipboard.copy(textToCopy);
  }

  private GetDays() {
    this._Lookup.GetDays().subscribe((days) => {
      this.daysOfWeek = days;
    });
  }

  private GetMySubjectSchedualGroup(_filter: any) {
    const filter = _filter;
    // Remove properties with value null
    Object.keys(filter).forEach(
      (key) =>
        (filter[key] === null && delete filter[key]) ||
        (filter[key] === '' && delete filter[key])
    );
    this._SubjectGroup.GetMySubjectSchedualGroup(filter).subscribe(
      (res) => {
        this.SubjectGroups = res;
        this.submit = true;
      },
      (err) => {
        this.submit = true;
      }
    );
  }

  /**
   *
   *@GetTeacherSubjectAcademicSemesterYearForList
   * @private
   * @memberof SubjectGroupComponent
   */
  private GetTeacherSubjectAcademicSemesterYearForList() {
    this._SubjectGroup
      .GetTeacherSubjectAcademicSemesterYearForList()
      .subscribe((res) => {
        this.SubjectAcademicSemesterYearForList = res;
      });
  }

  protected TeacherLessonForList!: IGetTeacherLessonForList[];

  /**
   *
   * @GetTeacherLessonForList
   * @private
   * @memberof GroupForLessonComponent
   */
  alertFlag: boolean = false;
  private GetAllTeacherLessonForList() {
    this._GroupForLessonService
      .GetAllTeacherLessonForList(
        this.fc['teacherSubjectAcademicSemesterYearId'].value
      )
      .subscribe((res) => {
        this.TeacherLessonForList = res;
        this.groupSessionCost.setValue(
          this.TeacherLessonForList[0].groupSessionCost ?? 0
        );
        this.alertFlag =
          this.TeacherLessonForList[0].groupSessionCost > 0 ? false : true;
        this.TeacherLessonForList.forEach((x) => {
          this.teacherLessonList.push(
            this._fb.group({
              id: [x.id],
              count: [
                x.count,
                [Validators.max(5), Validators.min(1), Validators.required],
              ],
              groupDuration: [x.groupDuration],
              lessonName: [x.lessonName],
              checkbox: [false, Validators.required],
              groupSessionCost: [x.groupSessionCost, Validators.required],

              order: [x.order, [Validators.min(1), Validators.required]],
            })
          );
        });
      });
  }
  /**
   *
   * @get Name Subject Academic Semester
   * @protected
   * @param {number} [index] remove index parameter
   * @memberof SubjectGroupComponent

   */
  protected ChangeSubjectAcademicSemester() {
    this.teacherLessonList.clear();
    this.TeacherLessonForList = [];
    const foundObject = this.SubjectAcademicSemesterYearForList.find(
      (item) => item.id == this.fc['teacherSubjectAcademicSemesterYearId'].value
    );
    if (foundObject != null) {
      this.fc['teacherSubjectAcademicSemesterYearName'].setValue(
        foundObject.subjectDisplayName
      );
      this.GetAllTeacherLessonForList();
    } else {
      this.TeacherLessonForList = [];
      this.alertFlag = false;
      this.teacherLessonList.setValue([]);
    }
  }

  private GetReviewSubjectSchedualGroup(ModelSchedualGroup: ISubjectGroup) {
    this.submit = false;
    this._SubjectGroup
      .GetReviewSubjectSchedualGroup(ModelSchedualGroup)
      .subscribe(
        (res) => {
          this.IsReview = true;
          this.SubjectGroupsIsReview = res;
          this.submit = true;

          window.scroll(0, 0);
        },
        (err) => {
          this.submit = true;
        }
      );
  }

  GetReviewSubjectSchedualGroupInTable(ModelSchedualGroupId: number) {
    this._SubjectGroup
      .GetSubjectSchedualGroupDetailsById(ModelSchedualGroupId)
      .subscribe((res) => {
        this.IsReview = true;
        this.SubjectGroupsIsReview = res;
        this.seoService.setTitle(
          this.SubjectGroupsIsReview.teacherSubjectAcademicSemesterYearName
        );
        this.seoService.setHostUrlIndex();
        window.scroll(0, 0);
      });
  }

  protected handleEvent(event: any) {
    // this.SearchForm.reset();
    this.IsReview = event?.isReview;
    if (event?.isInvalid) this.handleEventIsAdded();
    this.seo();
  }

  protected handleEventIsAdded() {
    this.initiateForm();
    this.addRow();
    this.SearchForm.reset();
    this.TeacherLessonForList = [];
    this.alertFlag = false;

    this.GetMySubjectSchedualGroup({});
  }
  private seo() {
    this.seoService.setMetaImage('assets/images/MrS-Cool/setting.png');
    const lang = this.layoutService.config.langu;
    this.seoService.loadTranslations(lang).subscribe((translations) => {
      this.seoService.setTitle(translations.SubjectGroup.Tabble.header.Title);
      this.seoService.setHostUrlIndex();
      this.seoService.setMetaDescription(
        translations.Home.header.meta_description
      );
      this.seoService.setMetaKeywords(
        this.seoService.generateKeywords(
          translations.Home.header.meta_description
        )
      );
    });
  }

  editKey: number | null = null;

  startEdit(key: number): void {
    this.editKey = key;
  }

  AddAndUpdateSessions(SubjectGroup: ISubjectGroup) {
    const modalRef = this.modal.open(AddSessionModleComponent, { size: 'lg' });

    if (SubjectGroup) modalRef.componentInstance.id = SubjectGroup.id; // Pass the ID to the modal component
    modalRef.componentInstance.teacherSubjectAcademicSemesterYearId =
      SubjectGroup.teacherSubjectAcademicSemesterYearId; // Pass the ID to the modal component
    modalRef.result.then(
      (result) => {
        // this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
         this.GetMySubjectSchedualGroup({});
      }
    );
  }

  serverTime!: any;

  private GetEgyptDateTime() {
    this.AuthService.GetEgyptDateTime().subscribe({
      next: (data: any) => {
        console.log('data', data);
        try {
          // const parsedDate = new Date("2024-12-18T13:27"); // Convert the string to a Date object
          // const parsedDate = new Date(data); // Convert the string to a Date object
          this.serverTime = data;
          this.serverTime = this.serverTime.replace(/[T]/g, '-');
          console.log(this.serverTime);
          this.fc['dateFrom'].setValue(this.serverTime.slice(0, 10));
          this.onSubmit();
        } catch (error) {
          console.error('Failed to parse date:', error);
        }
      },
      error: (error) => {
        console.error('Error fetching date:', error);
      },
    });
  }
}
