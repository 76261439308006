<!-- <div class="w-full sm:w-1/1 md:w-1/1  xl:w-1/1 pt-5 px-3 md:ps-12  "> -->
<div class="trxt-center youtube">
  <iframe
    class="lg:w-[500px]"
    height="315"
    src="https://www.youtube.com/embed/WGTT_cqWqTI?si=r7h89qixrZB-oslr"
    title="YouTube video player"
    frameborder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
    referrerpolicy="strict-origin-when-cross-origin"
    allowfullscreen
  ></iframe>
  <p class="text-black text-center text-[16px] Lama-Regular my-2">
    {{ "SubjectGroup.header.Explanation" | translate }}
  </p>
</div>

<div *ngIf="!IsReview">
  <mat-accordion>
    <!-- #docregion basic-panel -->

    <!-- #enddocregion basic-panel -->
    <mat-expansion-panel
      class="dark:bg-[#1a202c] bg-[#ffffff] rounded-lg cardsSpace shadow dark:border dark:border-gray-700"
      style="border-radius: 15px"
      (opened)="panelOpenState = true"
      (closed)="panelOpenState = false"
    >
      <mat-expansion-panel-header
        class="bg-[var(--theme-deafult-studentWithClick)] hover:bg-[var(--theme-deafult-studentWithClick)] card-header CardHeader text-center border-0"
      >
        <!-- <mat-panel-title>
            <div class="card-header CardHeader text-center border-0">
              <h5 class="m-0 md:p-2 text-[14px] md:text-[16px] text-white Lama-bold">
                {{ "TeacherForSubjects.form.Filter" | translate }}
              </h5>
            </div>
          </mat-panel-title> -->
        <mat-panel-description style="display: block">
          <p class="mb-0 mt-3 text-left rtl:text-end  Notice" style="color: red">
            {{ "Notice" | translate }} :
            {{ "lesson_schedule_time" | translate }}

            <span class="mx-2"> {{ serverTime.slice(0, 16) }}</span>
          </p>

          <div
            class="d-flex align-items-center border-b-2 mainAddressPadding dark:border dark:bg-[#1a202c] dark:border-gray-700 px-6 pt-2 mb-3 w-full"
          >
            <div class="pb-3">
              <img src="assets/images/MrS-Cool/setting.png" alt="" />
            </div>
            <div class="addressIconSpace">
              <h2 class="text-left Lama-bold dark:text-white underTitle">
                {{ "SubjectGroup.header.Filter-SubjectGroup" | translate }}
              </h2>
            </div>
          </div>
        </mat-panel-description>
      </mat-expansion-panel-header>

      <div
        class="dark:bg-[#1a202c] bg-[#ffffff] rounded-lg cardsSpace shadow dark:border dark:bg-[#1a202c] dark:border-gray-700 mt-3 pb-4 pt-1"
      >
        <form class="max-md:px-1" [formGroup]="SearchForm">
          <div class="grid gap-x-5 grid-cols-1 px-6 max-md:mb-3">
            <div class="lg:grid-cols-4 grid gap-4 px-6 max-md:mb-3">
              <div class="mx-2 max-md:mb-2">
                <label
                  for="groupName"
                  class="block mb-1 text-sm font-medium text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
                >
                  {{ "SubjectGroup.form.Group-Name" | translate }}
                </label>
                <input
                  type="text"
                  formControlName="groupName"
                  id="SearchInp"
                  class="bg-white border border-gray-300 text-[var(--theme-deafult-TeacherWithClick)] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Enter group Name"
                />
              </div>
              <div class="mx-2 max-md:mb-2">
                <label
                  for="startDate"
                  class="block mb-1 text-sm font-medium text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
                >
                  {{ "SubjectGroup.form.SDate" | translate }}
                </label>
                <input
                  type="date"
                  (keypress)="$event.preventDefault()"
                  formControlName="startDate"
                  id="startDate"
                  class="bg-white border border-gray-300 text-[var(--theme-deafult-TeacherWithClick)] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Enter start Date"
                />
              </div>
              <div class="mx-2 max-md:mb-2">
                <label
                  for="endDate"
                  class="block mb-1 text-sm font-medium text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
                >
                  {{ "SubjectGroup.form.EDate" | translate }}
                </label>
                <input
                  type="date"
                  (keypress)="$event.preventDefault()"
                  formControlName="endDate"
                  id="endDate"
                  class="bg-white border border-gray-300 text-[var(--theme-deafult-TeacherWithClick)] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Enter End Date"
                />
              </div>
              <div class="lg:col-span-2 mx-2 max-md:mb-2">
                <label
                  for="teacherSubjectAcademicSemesterYear"
                  class="block mb-1 text-sm font-medium text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
                >
                  {{ "SubjectGroup.form.Subject-Name" | translate }}
                </label>

                <ng-select
                  id="teacherSubjectAcademicSemesterYear"
                  #teacherSubjectAcademicSemesterYear
                  [selectOnTab]="true"
                  formControlName="teacherSubjectAcademicSemesterYearId"
                  class="form-control p-0"
                  dropdownPosition="auto"
                  labelForId="validation02"
                  containerClass=""
                  [virtualScroll]="true"
                  [searchable]="true"
                >
                  <ng-option value="">
                    {{
                      "SubjectGroup.form.ChooseSubject" | translate
                    }}</ng-option
                  >

                  <ng-option
                    *ngFor="
                      let item of SubjectAcademicSemesterYearForList;
                      let i = index
                    "
                    [value]="item.id"
                    >{{ item.subjectDisplayName }}
                  </ng-option>
                </ng-select>
              </div>
              <div
                class="text-right align-items-end d-flex lg:col-span-2 justify-end mx-2 max-md:mb-2"
              >
                <button
                  type="submit"
                  (click)="ChangeSearchInp()"
                  class="text-white bg-[var(--theme-deafult-TeacherWithClick)] btnFilter hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-[#1a202c] dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700"
                >
                  {{ "SubjectGroup.form.Filter" | translate }}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </mat-expansion-panel>
  </mat-accordion>

  <mat-accordion>
    <!-- #docregion basic-panel -->

    <!-- #enddocregion basic-panel -->
    <mat-expansion-panel
      class="dark:bg-[#1a202c] bg-[#ffffff] rounded-lg cardsSpace shadow dark:border dark:border-gray-700"
      style="border-radius: 15px"
      (opened)="panelOpenState = true"
      [expanded]="true"
      (closed)="panelOpenState = false"
    >
      <mat-expansion-panel-header
        class="bg-[var(--theme-deafult-studentWithClick)] hover:bg-[var(--theme-deafult-studentWithClick)] card-header CardHeader text-center border-0"
      >
        <!-- <mat-panel-title>
            <div class="card-header CardHeader text-center border-0">
              <h5 class="m-0 md:p-2 text-[14px] md:text-[16px] text-white Lama-bold">
                {{ "TeacherForSubjects.form.Filter" | translate }}
              </h5>
            </div>
          </mat-panel-title> -->
        <mat-panel-description style="display: block">
          <p class="mb-0 mt-3 text-left rtl:text-end  Notice" style="color: red">
            {{ "Notice" | translate }} :
            {{ "lesson_schedule_time" | translate }}

            <span class="mx-2"> {{ serverTime.slice(0, 16) }}</span>
          </p>

          <div
            class="d-flex align-items-center border-b-2 dark:bg-[#1a202c] mainAddressPadding rounded-lg dark:border dark:bg-[#1a202c] dark:border-gray-700 px-6 pt-2 mb-3 w-full"
          >
            <div class="pb-3">
              <img src="assets/images/MrS-Cool/setting.png" alt="" />
            </div>
            <div class="addressIconSpace">
              <h3 class="text-left Lama-bold dark:text-white underTitle">
                {{ "SubjectGroup.Tabble.header.Title" | translate }}
              </h3>
            </div>
          </div>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div>
        <div class="relative overflow-x-auto dark:bg-[#1a202c]">
          <div
            class="d-flex align-items-center underAddressLine startPagePadding rounded-lg dark:border dark:bg-[#1a202c] dark:border-gray-700 px-6 pt-2 mb-3"
          >
            <table
              class="w-full text-sm text-left rtl:text-right text-white-500 dark:text-white-400 dark:bg-[#1a202c]"
            >
              <thead
                class="Lama-Medium text-white bg-[var(--theme-deafult-TeacherWithClick)] dark:bg-[#1a202c] dark:text-white-400"
              >
                <tr>
                  <th scope="col" class="px-6 py-3">
                    {{ "SubjectGroup.Tabble.thead.Subject" | translate }}
                  </th>
                  <th scope="col" class="px-6 py-3">
                    {{ "SubjectGroup.Tabble.thead.Group" | translate }}
                  </th>
                  <th scope="col" class="px-6 py-3">
                    {{ "SubjectGroup.Tabble.thead.SDate" | translate }}
                  </th>
                  <th scope="col" class="px-6 py-3">
                    {{ "SubjectGroup.Tabble.thead.EDate" | translate }}
                  </th>
                  <th scope="col" class="px-6 py-3">
                    {{ "GroupForLesson.form.groupCost" | translate }}
                  </th>
                  <th scope="col" class="px-6 py-3">
                    <!-- {{ "SubjectGroup.Tabble.thead.Action" | translate }} -->
                  </th>
                  <th scope="col" class="px-6 py-3">
                    {{ "SubjectGroup.Tabble.thead.Action" | translate }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  class="bg-white border-b-2 dark:bg-[#1a202c] dark:border-gray-700"
                  *ngFor="let item of SubjectGroups; let y = index"
                >
                  <th
                    scope="row"
                    class="px-6 py-4 font-medium text-[var(--theme-deafult-TeacherWithClick)] whitespace-nowrap dark:text-white"
                  >
                    {{ item.teacherSubjectAcademicSemesterYearName }}
                  </th>
                  <td class="px-6 py-4">
                    {{ item.groupName }}
                  </td>
                  <td class="px-6 py-4">
                    {{ item.startDate | date }}
                  </td>
                  <td class="px-6 py-4">
                    {{ item.endDate | date }}
                  </td>
                  <td class="px-6 py-4">
                    {{ item.groupCost }}
                  </td>
                  <td class="px-6 py-4">
                    <a
                      (click)="AddAndUpdateSessions(item)"
                      class="font-medium text-red-600 red:text-blue-500 hover:underline"
                    >
                      {{ "newSession" | translate }}</a
                    >
                  </td>
                  <td class="px-6 py-4 md:flex justify-between">
                    <a
                      (click)="GetReviewSubjectSchedualGroupInTable(item.id)"
                      class="font-medium text-red-600 red:text-blue-500 hover:underline mx-1"
                    >
                      <img src="assets/images/Icons/Group.png" alt="" srcset=""
                    /></a>

                    <a
                      (click)="
                        TableDeleteGroupID = item.id; openModal(template)
                      "
                      class="font-medium text-red-600 red:text-blue-500 hover:underline"
                    >
                      <img
                        src="assets/images/Icons/delete.png"
                        alt="Icons delete"
                        srcset=""
                    /></a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- </div> -->

        <ng-template #template>
          <div class="" style="position: relative; top: 50%">
            <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
              <div>
                <div class="p-6 text-center">
                  <div class="text-center">
                    <img
                      src="assets/images/MrS-Cool/delete-Icon.png"
                      class="m-auto p-3"
                      alt=""
                    />
                  </div>
                  <h3
                    class="mb-3 text-lg font-normal text-gray-500 dark:text-gray-400"
                  >
                    {{
                      "SubjectGroup.form.Are-you-sure-you-want-to-delete"
                        | translate
                    }}
                  </h3>
                  <button
                    (click)="
                      this.submit = false;
                      DeleteSubjectTeacherScheduleGroup(TableDeleteGroupID);
                      modalRef?.hide()
                    "
                    [disabled]="!submit"
                    type="button"
                    class="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2"
                  >
                    {{ "SubjectGroup.form.Yes" | translate }}
                  </button>
                  <button
                    (click)="modalRef?.hide()"
                    type="button"
                    class="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-[var(--theme-deafult-TeacherWithClick)] focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                  >
                    {{ "SubjectGroup.form.No" | translate }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </div>
    </mat-expansion-panel>
  </mat-accordion>

  <mat-accordion>
    <!-- #docregion basic-panel -->

    <!-- #enddocregion basic-panel -->
    <!-- [expanded]="dynamicForm.valid" -->

    <mat-expansion-panel
      class="dark:bg-[rgb(26,32,44)] bg-[#ffffff] rounded-lg cardsSpace shadow dark:border dark:border-gray-700"
      style="border-radius: 15px"
      (opened)="panelOpenState = true"
      (closed)="panelOpenState = false"
    >
      <mat-expansion-panel-header
        class="bg-[var(--theme-deafult-studentWithClick)] hover:bg-[var(--theme-deafult-studentWithClick)] card-header CardHeader text-center border-0"
      >
        <!-- <mat-panel-title>
            <div class="card-header CardHeader text-center border-0">
              <h5 class="m-0 md:p-2 text-[14px] md:text-[16px] text-white Lama-bold">
                {{ "TeacherForSubjects.form.Filter" | translate }}
              </h5>
            </div>
          </mat-panel-title> -->
        <mat-panel-description style="display: block">
          <p class="mb-0 mt-3 text-left rtl:text-end  Notice" style="color: red">
            {{ "Notice" | translate }} :
            {{ "lesson_schedule_time" | translate }}

            <span class="mx-2"> {{ serverTime.slice(0, 16) }}</span>
          </p>

          <div
            class="d-flex align-items-center mainAddressPadding border-b-2 rounded-lg dark:border dark:bg-[#1a202c] dark:border-gray-700 px-6 pt-2 w-full"
          >
            <div class="pb-3">
              <img src="assets/images/Icons/add-file-button_.png" alt="" />
            </div>
            <div class="addressIconSpace">
              <h3 class="text-left Lama-bold dark:text-white underTitle">
                {{ "SubjectGroup.header.Add" | translate }}
              </h3>
            </div>
          </div>
        </mat-panel-description>
      </mat-expansion-panel-header>

      <div>
        <form
          class="mb-5 max-md:px-1"
          [formGroup]="dynamicForm"
          (ngSubmit)="onSubmit()"
        >
          <div
            class="grid gap-2 lg:grid-cols-2 md:grid-cols-1 grid-cols-1 px-6 startPagePadding"
          >
            <div class="col-span-2">
              <label
                for="teacherSubjectAcademicSemesterYear_"
                class="block mb-2 text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
                >{{ "SubjectGroup.form.Subject" | translate }}
                <span
                  class="text-[#FF0000] text-[12px] md:text-[16px] Lama-SemiBold"
                  >*</span
                ></label
              >
              <ng-select
                id="teacherSubjectAcademicSemesterYear_"
                #teacherSubjectAcademicSemesterYear_
                [selectOnTab]="true"
                formControlName="teacherSubjectAcademicSemesterYearId"
                class="form-control p-0"
                (change)="ChangeSubjectAcademicSemester()"
                dropdownPosition="auto"
                labelForId="validation02"
                [tooltip]="fc['teacherSubjectAcademicSemesterYearId'].errors?.['required'] ?  ('this field is required' | translate) : null"
                [isDisabled]="
                  !fc['teacherSubjectAcademicSemesterYearId'].errors &&
                  fc['teacherSubjectAcademicSemesterYearId'].touched
                "
                containerClass=""
                [searchable]="true"
                [class.is-valid]="
                  fc['teacherSubjectAcademicSemesterYearId'].valid &&
                  (fc['teacherSubjectAcademicSemesterYearId'].touched ||
                    fc['teacherSubjectAcademicSemesterYearId'].value)
                "
                [class.is-invalid]="
                  fc['teacherSubjectAcademicSemesterYearId'].invalid &&
                  fc['teacherSubjectAcademicSemesterYearId'].touched
                "
              >
                <ng-option value="">
                  {{ "SubjectGroup.form.ChooseSubject" | translate }}
                </ng-option>

                <ng-option
                  *ngFor="
                    let item of SubjectAcademicSemesterYearForList;
                    let i = index
                  "
                  [value]="item.id"
                  >{{ item.subjectDisplayName }}
                </ng-option>
              </ng-select>
            </div>
            <div class="col-span-2">
              <mat-accordion
                *ngIf="TeacherLessonForList && TeacherLessonForList.length"
              >
                <!-- #docregion basic-panel -->

                <!-- #enddocregion basic-panel -->
                <mat-expansion-panel
                  class="dark:bg-[#1a202c] bg-[#ffffff] rounded-lg cardsSpace shadow dark:border dark:border-gray-700"
                  style="border-radius: 15px"
                  (opened)="panelOpenState = true"
                  [expanded]="true"
                  (closed)="panelOpenState = false"
                >
                  <mat-expansion-panel-header
                    class="bg-[var(--theme-deafult-studentWithClick)] hover:bg-[var(--theme-deafult-studentWithClick)] card-header CardHeader text-center border-0"
                  >
                    <!-- <mat-panel-title>
            <div class="card-header CardHeader text-center border-0">
              <h5 class="m-0 md:p-2 text-[14px] md:text-[16px] text-white Lama-bold">
                {{ "TeacherForSubjects.form.Filter" | translate }}
              </h5>
            </div>
          </mat-panel-title> -->
                    <mat-panel-description>
                      <div
                        class="d-flex align-items-center border-b-2 dark:bg-[#1a202c] mainAddressPadding rounded-lg dark:border dark:bg-[#1a202c] dark:border-gray-700 px-6 pt-2 mb-3 w-full"
                      >
                        <div class="addressIconSpace">
                          <p
                            class="text-left Lama-bold dark:text-white underTitle"
                          >
                            {{
                              "SubjectGroup.Tabble.header.Lessons" | translate
                            }}
                          </p>
                        </div>
                      </div>
                    </mat-panel-description>
                  </mat-expansion-panel-header>
                  <div>
                    <div class="relative overflow-x-auto dark:bg-[#1a202c]">
                      <div class="px-6">
                        <label
                          for="groupSessionCost"
                          class="block mb-2 text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
                        >
                          {{
                            "ManageMySubjects.form.groupSessionCost" | translate
                          }}
                          <span
                            class="text-[#FF0000] text-[12px] md:text-[16px] Lama-SemiBold"
                            >*</span
                          ></label
                        >

                        <input
                          [formControl]="groupSessionCost"
                          [max]="1"
                          type="text"
                          appOnlyNumbersWithDouble
                          class="form-control d-inline-block w-auto"
                          [tooltip]="
                            groupSessionCost.invalid &&
                            (groupSessionCost.dirty || groupSessionCost.touched)
                              ? ('SubjectGroup.form.ValidatorsgroupSessionCost'
                                | translate)
                              : null
                          "
                          [ngClass]="{
        'class-1': groupSessionCost.invalid && (groupSessionCost.dirty || groupSessionCost.touched),
        'class-2': groupSessionCost.valid && (groupSessionCost.dirty || groupSessionCost.touched),
      }"
                        />

                        <p
                          *ngIf="groupSessionCost.value <= 0 && alertFlag"
                          class="pt-2"
                        >
                          {{ "modify_group_price" | translate }}
                          <a
                            routerLink="/main/ManageMySubjects"
                            rel="noopener noreferrer"
                          >
                            {{ "Click_hear" | translate }}
                          </a>
                        </p>
                      </div>
                      <div
                        class="d-flex align-items-center underAddressLine startPagePadding rounded-lg dark:border dark:bg-[#1a202c] dark:border-gray-700 px-6 mb-3"
                      >
                        <table
                          class="w-full text-sm text-left rtl:text-right text-white-500 dark:text-white-400 dark:bg-[#1a202c]"
                        >
                          <thead
                            class="Lama-Medium text-white bg-[var(--theme-deafult-TeacherWithClick)] dark:bg-[#1a202c] dark:text-white-400"
                          >
                            <tr>
                              <th scope="col" class="px-6 py-3">
                                {{
                                  "GroupForLesson.Tabble.thead.Lesson"
                                    | translate
                                }}
                              </th>
                              <th scope="col" class="px-6 py-3">
                                {{
                                  "SubjectGroup.Tabble.thead.Order" | translate
                                }}
                              </th>
                              <th scope="col" class="px-6 py-3">
                                {{
                                  "SubjectGroup.Tabble.thead.Count" | translate
                                }}
                              </th>
                              <!-- <th scope="col" class="px-6 py-3">
                {{ "ManageMySubjects.form.groupSessionCost" | translate }}
              </th> -->
                              <th scope="col" class="px-6 py-3">
                                {{
                                  "SubjectGroup.Tabble.thead.checkbox"
                                    | translate
                                }}
                              </th>

                              <th scope="col" class="px-6 py-3">
                                {{
                                  "SubjectGroup.Tabble.thead.Action" | translate
                                }}
                              </th>
                            </tr>
                          </thead>
                          <tbody formArrayName="teacherLessonList">
                            <tr
                              class="bg-white border-b-2 dark:bg-[#1a202c] dark:border-gray-700"
                              *ngFor="
                                let lesson of TeacherLessonForList;
                                let y = index
                              "
                              [formGroupName]="y"
                            >
                              <th
                                scope="row"
                                class="px-6 py-4 font-medium text-[var(--theme-deafult-TeacherWithClick)] whitespace-nowrap dark:text-white"
                              >
                                {{ lesson.lessonName }}
                              </th>

                              <td class="px-6 py-4">
                                <div *ngIf="editKey !== lesson.id">
                                  {{
                                    teacherLessonList.at(y).get("order")?.value
                                  }}
                                </div>
                                <div *ngIf="editKey === lesson.id">
                                  <input
                                    formControlName="order"
                                    type="number"
                                    class="form-control d-inline-block w-auto"
                                    [tooltip]="
                                      teacherLessonList.at(y).get('order')
                                        ?.invalid &&
                                      (teacherLessonList.at(y).get('order')
                                        ?.dirty ||
                                        teacherLessonList.at(y).get('order')
                                          ?.touched)
                                        ? ('SubjectGroup.form.ValidatorsOrder'
                                          | translate)
                                        : null
                                    "
                                    [ngClass]="{
                  'class-1': teacherLessonList.at(y).get('order')?.invalid && (teacherLessonList.at(y).get('order')?.dirty || teacherLessonList.at(y).get('order')?.touched),
                  'class-2': teacherLessonList.at(y).get('order')?.valid && (teacherLessonList.at(y).get('order')?.dirty || teacherLessonList.at(y).get('order')?.touched),
                }"
                                  />
                                </div>
                              </td>
                              <td class="px-6 py-4">
                                <div *ngIf="editKey !== lesson.id">
                                  {{
                                    teacherLessonList.at(y).get("count")?.value
                                  }}
                                </div>
                                <div *ngIf="editKey === lesson.id">
                                  <input
                                    formControlName="count"
                                    [max]="1"
                                    type="text"
                                    onlyNumbers
                                    class="form-control d-inline-block w-auto"
                                    [tooltip]="
                                      teacherLessonList.at(y).get('count')
                                        ?.invalid &&
                                      (teacherLessonList.at(y).get('count')
                                        ?.dirty ||
                                        teacherLessonList.at(y).get('count')
                                          ?.touched)
                                        ? ('SubjectGroup.form.ValidatorsCount'
                                          | translate)
                                        : null
                                    "
                                    [ngClass]="{
                  'class-1': teacherLessonList.at(y).get('count')?.invalid && (teacherLessonList.at(y).get('count')?.dirty || teacherLessonList.at(y).get('count')?.touched),
                  'class-2': teacherLessonList.at(y).get('count')?.valid && (teacherLessonList.at(y).get('count')?.dirty || teacherLessonList.at(y).get('count')?.touched),
                }"
                                  />
                                </div>
                              </td>
                              <!-- <td class="px-6 py-4">

                <div *ngIf="editKey !== lesson.id">
                  {{teacherLessonList.at(y).get('groupSessionCost')?.value }}
                </div>
                <div *ngIf="editKey === lesson.id">
                  <input  formControlName="groupSessionCost" [max]="1"  type="text" onlyNumbers class="form-control d-inline-block w-auto"

                  [tooltip]="
                  teacherLessonList.at(y).get('groupSessionCost')?.invalid &&
                  (teacherLessonList.at(y).get('groupSessionCost')?.dirty || teacherLessonList.at(y).get('groupSessionCost')?.touched)
                    ? ('SubjectGroup.form.ValidatorsgroupSessionCost' | translate)
                    : null
                "
                [ngClass]="{
                  'class-1': teacherLessonList.at(y).get('groupSessionCost')?.invalid && (teacherLessonList.at(y).get('groupSessionCost')?.dirty || teacherLessonList.at(y).get('groupSessionCost')?.touched),
                  'class-2': teacherLessonList.at(y).get('groupSessionCost')?.valid && (teacherLessonList.at(y).get('groupSessionCost')?.dirty || teacherLessonList.at(y).get('groupSessionCost')?.touched),
                }"
                  />
                </div>
              </td> -->
                              <td class="px-6 py-4">
                                <input
                                  formControlName="checkbox"
                                  id="remember{{ y }}"
                                  type="checkbox"
                                  [tooltip]="
                                    teacherLessonList.at(y).get('checkbox')
                                      ?.invalid &&
                                    (teacherLessonList.at(y).get('checkbox')
                                      ?.dirty ||
                                      teacherLessonList.at(y).get('checkbox')
                                        ?.touched)
                                      ? ('SubjectGroup.form.ValidatorsCount'
                                        | translate)
                                      : null
                                  "
                                  [ngClass]="{
                                    'class-1':
                                      teacherLessonList.at(y).get('checkbox')
                                        ?.invalid &&
                                      (teacherLessonList.at(y).get('checkbox')
                                        ?.dirty ||
                                        teacherLessonList.at(y).get('checkbox')
                                          ?.touched),
                                    'class-2':
                                      teacherLessonList.at(y).get('checkbox')
                                        ?.valid &&
                                      (teacherLessonList.at(y).get('checkbox')
                                        ?.dirty ||
                                        teacherLessonList.at(y).get('checkbox')
                                          ?.touched)
                                  }"
                                />
                              </td>

                              <td class="px-6 py-4 d-flex justify-between">
                                <!-- (click)="saveEdit(lesson.key)" -->
                                <a
                                  *ngIf="
                                    editKey !== lesson.id &&
                                    teacherLessonList.at(y).get('checkbox')
                                      ?.value == true
                                  "
                                  class="btn btn-primary btn-sm ms-2"
                                  (click)="startEdit(lesson.id)"
                                  class="font-medium text-red-600 red:text-blue-500 hover:underline"
                                >
                                  <img
                                    src="assets/images/Icons/edit.png"
                                    alt=""
                                    srcset=""
                                /></a>
                                <a
                                  *ngIf="editKey == lesson.id"
                                  class="btn btn-primary btn-sm ms-2"
                                  (click)="
                                    teacherLessonList.at(y).get('count')
                                      ?.invalid ||
                                    teacherLessonList.at(y).get('order')
                                      ?.invalid
                                      ? null
                                      : (editKey = null)
                                  "
                                >
                                  <img
                                    src="assets/images/Icons/Done.png"
                                    alt=""
                                    srcset=""
                                  />
                                </a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <!-- </div> -->
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
            <div>
              <label
                for="countries"
                class="block mb-2 text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
              >
                {{ "SubjectGroup.form.Group" | translate }}
                <span
                  class="text-[#FF0000] text-[12px] md:text-[16px] Lama-SemiBold"
                  >*</span
                ></label
              >
              <input
                type="text"
                formControlName="groupName"
                [tooltip]="
                  fc['groupName'].invalid &&
                  (fc['groupName'].dirty || fc['groupName'].touched)
                    ? ('SubjectGroup.form.required' | translate)
                    : null
                "
                [ngClass]="{
                  'class-1': fc['groupName'].invalid && (fc['groupName'].dirty || fc['groupName'].touched),
                  'class-2': fc['groupName'].valid && (fc['groupName'].dirty || fc['groupName'].touched),
                }"
                id="groupName"
                class="bg-white border border-gray-300 text-[var(--theme-deafult-TeacherWithClick)] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="{{ 'SubjectGroup.form.PGroup' | translate }}"
                required
              />
            </div>

            <div>
              <label
                for="countries"
                class="block mb-2 text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
              >
                {{ "SubjectGroup.form.SDate" | translate }}
                <span
                  class="text-[#FF0000] text-[12px] md:text-[16px] Lama-SemiBold"
                  >*</span
                ></label
              >
              <input
                type="date"
                (keypress)="$event.preventDefault()"
                formControlName="startDate"
                [min]="expirationDateNow"
                [tooltip]="
                  fc['startDate'].invalid &&
                  (fc['startDate'].dirty || fc['startDate'].touched)
                    ? ('SubjectGroup.form.required' | translate)
                    : null
                "
                [ngClass]="{
                  'class-1': fc['startDate'].invalid && (fc['startDate'].dirty || fc['startDate'].touched),
                  'class-2': fc['startDate'].valid && (fc['startDate'].dirty || fc['startDate'].touched),
                }"
                id="startDate"
                class="bg-white border border-gray-300 text-[var(--theme-deafult-TeacherWithClick)] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
              />
            </div>
          </div>
          <div class="addressIconSpace pl30">
            <label class="text-left Lama-bold dark:text-white underTitle">
              {{ "SubjectGroup.Tabble.header.subtitle_" | translate }}
            </label>
          </div>

          <p class="border-b-2 col-span-4 m-0"></p>
          <div formArrayName="scheduleSlots">
            <div
              class="grid gap-8 lg:grid-cols-5 md:grid-cols-4 px-6"
              *ngFor="let row of rows.controls; let i = index; last as isLast"
              [formGroupName]="i"
            >
              <div class="col-span-2">
                <label
                  for="first_name"
                  class="block mb-1 text-sm font-medium text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
                >
                  {{ "SubjectGroup.form.Day" | translate }}
                  <span
                    class="text-[#FF0000] text-[12px] md:text-[16px] Lama-SemiBold"
                    >*</span
                  >
                </label>

                <ng-select
                  id="dayId"
                  #teacherSubjectAcademicSemesterYearSearch
                  [selectOnTab]="true"
                  formControlName="dayId"
                  class="form-control p-0"
                  dropdownPosition="auto"
                  labelForId="validation02"
                  [tooltip]="
                    rows.at(i).get('dayId')?.invalid &&
                    (rows.at(i).get('dayId')?.dirty ||
                      rows.at(i).get('dayId')?.touched)
                      ? ('SubjectGroup.form.required' | translate)
                      : null
                  "
                  [ngClass]="{
                  'class-1': rows.at(i).get('dayId')?.invalid && (rows.at(i).get('dayId')?.dirty || rows.at(i).get('dayId')?.touched),
                  'class-2': rows.at(i).get('dayId')?.valid && (rows.at(i).get('dayId')?.dirty || rows.at(i).get('dayId')?.touched),
                }"
                  [class.is-valid]="
                    rows.at(i).get('dayId')?.value &&
                    (rows.at(i).get('dayId')?.touched ||
                      rows.at(i).get('dayId')?.value)
                  "
                  [class.is-invalid]="
                    rows.at(i).get('dayId')?.invalid &&
                    rows.at(i).get('dayId')?.touched
                  "
                  [isDisabled]="
                    !rows.at(i).get('dayId')?.errors &&
                    rows.at(i).get('dayId')!.touched
                  "
                  containerClass=""
                  [searchable]="true"
                >
                  <ng-option value="">
                    {{ "Choose day" | translate }}</ng-option
                  >
                  <ng-option *ngFor="let item of daysOfWeek" [value]="item.id">
                    {{ item.name }}
                  </ng-option>
                </ng-select>
              </div>
              <div class="col-span-2">
                <label
                  for="timeFrom"
                  class="block mb-1 text-sm font-medium text-[var(--theme-deafult-TeacherWithClick)] dark:text-white"
                >
                  {{ "SubjectGroup.form.STime" | translate
                  }}<span
                    class="text-[#FF0000] text-[12px] md:text-[16px] Lama-SemiBold"
                    >*</span
                  >
                </label>
                <input
                  type="time"
                  formControlName="timeFrom"
                  [tooltip]="
                    rows.at(i).get('timeFrom')?.invalid &&
                    (rows.at(i).get('timeFrom')?.dirty ||
                      rows.at(i).get('timeFrom')?.touched)
                      ? ('SubjectGroup.form.required' | translate)
                      : null
                  "
                  [ngClass]="{
                'class-1': rows.at(i).get('timeFrom')?.invalid && (rows.at(i).get('timeFrom')?.dirty || rows.at(i).get('timeFrom')?.touched),
                'class-2': rows.at(i).get('timeFrom')?.valid && (rows.at(i).get('timeFrom')?.dirty || rows.at(i).get('timeFrom')?.touched),
              }"
                  id="timeFrom"
                  class="bg-white border border-gray-300 text-[var(--theme-deafult-TeacherWithClick)] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Enter your from Time"
                  required
                />
              </div>
              <div class="d-flex align-items-end justify-between">
                <div class="d-flex justify-between">
                  <a
                    *ngIf="!isLast"
                    (click)="deleteRow(i)"
                    [tooltip]="'SubjectGroup.form.tooltipClearRow' | translate"
                    class="font-medium mr-2 text-red-600 red:text-blue-500 hover:underline rounded mx-1 px-2 border-2 border-black"
                  >
                    <img
                      src="assets/images/Icons/delete.png"
                      alt="Icons delete"
                      srcset=""
                  /></a>
                  <a
                    *ngIf="isLast"
                    (click)="ClearRow(i)"
                    [tooltip]="'SubjectGroup.form.tooltipRemoveRow' | translate"
                    class="font-medium mr-2 text-red-600 red:text-blue-500 hover:underline py-2 px-2 border-2 border-black rounded"
                  >
                    <img
                      src="assets/images/Icons/removeRow.png"
                      alt=""
                      srcset=""
                  /></a>
                  <a
                    *ngIf="isLast"
                    (click)="addRow()"
                    [tooltip]="'SubjectGroup.form.tooltipPlus' | translate"
                    class="font-medium mr-2 text-white bg-[var(--theme-deafult-TeacherWithClick)] hover:bg-gray-900 focus:outline-none hover:underline py-2 px-2 border-2 border-black rounded"
                  >
                    <img src="assets/images/Icons/plus.png" alt="" srcset=""
                  /></a>
                </div>
              </div>
            </div>
          </div>

          <div
            class="text-right col-span-3 d-flex align-items-end justify-end startPagePadding"
          >
            <button
              type="submit"
              [disabled]="!submit"
              class="text-white bg-[var(--theme-deafult-TeacherWithClick)] btnWithForm hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm p-3 py-2.5 mr-4 dark:bg-[#1a202c] dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700"
            >
              {{ "SubjectGroup.form.Review" | translate }}
            </button>
            <button
              type="button"
              class="text-[var(--theme-deafult-TeacherWithClick)] btnWithForm bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm p-3 px-5 py-2.5 mr-4 dark:bg-[#1a202c] dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
              (click)="Clear()"
            >
              {{ "SubjectGroup.form.clear" | translate }}
            </button>
          </div>
        </form>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>

<app-group-schedule
  *ngIf="IsReview"
  (IsReview)="handleEvent($event)"
  [_InjectSubjectGroup]="_SubjectGroup"
  [TeacherSubjectGroup]="SubjectGroupsIsReview"
></app-group-schedule>
