import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { CommonModule, NgIf } from '@angular/common';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { Router } from '@angular/router';
import { ModalModule } from 'ngx-bootstrap/modal';

import { GroupForLessonService } from 'src/app/shared/Apis/Teacher/Geoup/group-for-lesson.service';
import { NgxPaginationModule } from 'ngx-pagination';
import { BaseComponent } from 'src/app/shared/components/Base/base.component';
import { TeacherFinanceService } from 'src/app/shared/Apis/Teacher/teacher-finance.service';
import {
  ITeacherPurchasedLessons,
  ITeacherPurchasedSubjects,
} from 'src/app/shared/Model/teacher/teacher-finance';
import Swal from 'sweetalert2';
import { TeacherPurchasedLessonsComponent } from '../teacher-purchased-lessons/teacher-purchased-lessons.component';

@Component({
  selector: 'app-teacher-purchased-subjects',
  standalone: true,
  imports: [
    CommonModule,
    NgIf,
    ReactiveFormsModule,
    TranslateModule,
    TooltipModule,
    ModalModule,
    NgxPaginationModule,
    TeacherPurchasedLessonsComponent,
  ],
  templateUrl: './teacher-purchased-subjects.component.html',
  styleUrls: ['./teacher-purchased-subjects.component.scss'],
})
export class TeacherPurchasedSubjectsComponent
  extends BaseComponent
  implements OnInit
{
  protected SearchForm!: FormGroup;
  page!: number;
  TeacherPurchasedSubjects!: ITeacherPurchasedSubjects[];

  constructor(
    private _fb: FormBuilder,
    protected _GroupForLessonService: GroupForLessonService,
    private router: Router,
    private TeacherFinanceService: TeacherFinanceService
  ) {
    super();
  }

  ngOnInit(): void {
    this.initiateSearch();
    this.GetTeacherPurchasedSubjects();
  }

  private initiateSearch(): void {
    this.SearchForm = this._fb.group({
      dateFrom: [''],
      dateTo: [''],
    });
  }

  get fc() {
    return this.SearchForm.controls;
  }

  protected GetTeacherPurchasedSubjects() {
    this.removeNullfromPager(this.pager);
    this.TeacherFinanceService.GetTeacherPurchasedSubjects(
      this.pager
    ).subscribe((res) => {
      this.TeacherPurchasedSubjects = res.items;
      this.totalRecords = res.totalCount;
    });
  }

  removeNullfromPager(pager: any): void {
    for (const prop in pager) {
      if (
        (pager.hasOwnProperty(prop) && pager[prop] === null) ||
        pager[prop] == ''
      ) {
        delete pager[prop];
      }
    }
  }

  navigate(id: number) {
    this.router.navigate([`/main/CompletedLessonDetail/${id}`]);
  }

  pageChanged(page: any) {
    this.expandedRowIndex = null;
    this.TeacherPurchasedLessons = [];
    this.pageNumber = page; // -1 * pageSize;
    this.pager.skipCount = (this.pageNumber - 1) * this.pager.maxResultCount;
    this.GetTeacherPurchasedSubjects();
    this.pageNumber;
  }
  SearchFormPag() {
    this.pageNumber = 1; // -1 * pageSize;
    this.pager.skipCount = 0;
    this.pager = { ...this.pager, ...this.SearchForm.getRawValue() };

    this.GetTeacherPurchasedSubjects();
    this.pageNumber;
  }

  protected onSubmit() {
    // this.submit = false;
     this.expandedRowIndex = null;
      this.TeacherPurchasedLessons = [];
    if (this.fc['dateFrom'].value && this.fc['dateTo'].value) {
      const fromStartDate = this.fc['dateFrom'].value;
      const toEndDate = this.fc['dateTo'].value;

      if (fromStartDate > toEndDate) {
        // this.notifier.showError('Start Date Should Be Before To End Date');
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Start Date Should Be Before To End Date',
        });
        // this.submit = true;
      } else {
        this.SearchFormPag();
      }
    } else {
      this.SearchFormPag();
    }
  }

  TeacherPurchasedLessons!: ITeacherPurchasedLessons[];

  expandedRowIndex: number | null = null;

  toggleRow(index: number, teacherLessonSessionId: number): void {
    // this.expandedRowIndex = this.expandedRowIndex === index ? null : index;
console.log(teacherLessonSessionId)
    if (this.expandedRowIndex === index) {
      this.expandedRowIndex = null;
      this.TeacherPurchasedLessons = [];
    }
    else {
      this.TeacherPurchasedLessons = [];

      this.expandedRowIndex = index;
      this.TeacherLessonsForSubjectGroup(teacherLessonSessionId);
    }
  }

  trackByFn(index: number, item: any): number {
    return item.id; // استخدم خاصية فريدة للعنصر
  }

  protected TeacherLessonsForSubjectGroup(teacherLessonSessionId: number) {
    this.TeacherFinanceService.TeacherLessonsForSubjectGroup(
      teacherLessonSessionId
    ).subscribe((res) => {
      this.TeacherPurchasedLessons = res;
    });
  }

  @ViewChild('scrollable') scrollable!: ElementRef;
  isDragging = false;
  startX = 0;
  scrollLeft = 0;

  onMouseDown(event: MouseEvent) {
    this.isDragging = true;
    this.startX = event.pageX - this.scrollable.nativeElement.offsetLeft;
    this.scrollLeft = this.scrollable.nativeElement.scrollLeft;
    event.preventDefault();
  }

  onMouseMove(event: MouseEvent) {
    if (!this.isDragging) return;
    const x = event.pageX - this.scrollable.nativeElement.offsetLeft;
    const walk = (x - this.startX) * 2; // Adjust scroll speed
    this.scrollable.nativeElement.scrollLeft = this.scrollLeft - walk;
  }

  onMouseUp() {
    this.isDragging = false;
  }

  onMouseLeave() {
    this.isDragging = false;
  }
}
