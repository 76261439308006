import { Component, Inject, OnInit, PLATFORM_ID, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/Apis/AuthService/auth.service';
import { SSRService } from 'src/app/shared/Apis/appsettings/ssr.service';
import { RouteInfo } from './sidebar.metadata';
import { Role } from 'src/app/shared/Enums/role';
import { ROUTES } from './sidebar-items';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  public sidebarItems!: RouteInfo[];
  userType?: string;
  isCollapsed = false;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: Object,
    private _router: Router,
    public _appSettings: AuthService
  ) {}

  ngOnInit() {
    // if (this._appSettings.getToken()) {

    this.GetRoutesFilter();
    this._appSettings.sidebar.subscribe((res) => {

      if (res) {
        this.GetRoutesFilter();
        this._appSettings.ReloadSidebar(false);
      }
    });
  }

  GetRoutesFilter() {

    let userRole = this._appSettings.getRoleName();
    this.sidebarItems = ROUTES.filter((x) => {
      return x.role.some(
        (item) => item.includes(userRole) || item.includes('All')
      );
    });
    if (userRole === Role.Admin) {
      this.userType = Role.Admin;
    } else if (userRole === Role.Teacher) {
      this.userType = Role.Teacher;
    } else if (userRole === Role.Parent) {
      this.userType = Role.Parent;
    } else if (userRole === Role.children) {
      this.userType = Role.Parent;
    } else if (userRole === Role.Student) {
      this.userType = Role.Student;
    } else {
      this.userType = Role.Admin;
    }
  }
  logOut(): void {
    if (isPlatformBrowser(this.platformId)) {
      // حفظ قيمة hoursPlusUtc مؤقتاً
      const hoursPlusUtc = localStorage.getItem('hoursPlusUtc');

      // مسح جميع العناصر من localStorage
      localStorage.clear();

      // إعادة تعيين قيمة hoursPlusUtc
      if (hoursPlusUtc) {
        localStorage.setItem('hoursPlusUtc', hoursPlusUtc);
      }

      // تعيين دور المستخدم كـ 'Anonymous'
      localStorage.setItem('role', 'Anonymous');

      // إعادة توجيه المستخدم إلى الصفحة الرئيسية
      this._router.navigate(['/main/home']);

      // تنفيذ دالة GetRoutesFilter
      this.GetRoutesFilter();
    }
  }

}
