import { Component, OnInit } from '@angular/core';
import { CommonModule, NgIf } from '@angular/common';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { Router } from '@angular/router';
import { ModalModule } from 'ngx-bootstrap/modal';

import { GroupForLessonService } from 'src/app/shared/Apis/Teacher/Geoup/group-for-lesson.service';
import { NgxPaginationModule } from 'ngx-pagination';
import { BaseComponent } from 'src/app/shared/components/Base/base.component';
import { SeoService } from 'src/app/shared/Apis/appsettings/seo.service';
import { LayoutService } from 'src/app/shared/core/layout.service/layout.service';
import { TeacherFinanceService } from 'src/app/shared/Apis/Teacher/teacher-finance.service';
import { ITeacherPurchasedLessons } from 'src/app/shared/Model/teacher/teacher-finance';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-teacher-purchased-lessons',
  standalone: true,
  imports: [
    CommonModule,
    NgIf,
    ReactiveFormsModule,
    TranslateModule,
    TooltipModule,
    ModalModule,
    NgxPaginationModule,
  ],
  templateUrl: './teacher-purchased-lessons.component.html',
  styleUrls: ['./teacher-purchased-lessons.component.scss'],
})
export class TeacherPurchasedLessonsComponent
  extends BaseComponent
  implements OnInit
{
  protected SearchForm!: FormGroup;
  page!: number;
  TeacherPurchasedLessons!: ITeacherPurchasedLessons[];

  constructor(
    private _fb: FormBuilder,
    protected _GroupForLessonService: GroupForLessonService,
    private router: Router,
    private TeacherFinanceService: TeacherFinanceService
  ) {
    super();
  }

  ngOnInit(): void {
    this.initiateSearch();
    this.GetTeacherPurchasedLessons();
  }

  private initiateSearch(): void {
    this.SearchForm = this._fb.group({
      dateFrom: [''],
      dateTo: [''],
    });
  }

  get fc() {
    return this.SearchForm.controls;
  }

  protected GetTeacherPurchasedLessons() {
    this.removeNullfromPager(this.pager);
    this.TeacherFinanceService.GetTeacherPurchasedLessons(this.pager).subscribe(
      (res) => {
        this.TeacherPurchasedLessons = res.items;
        this.totalRecords = res.totalCount;
      }
    );
  }

  removeNullfromPager(pager: any): void {
    for (const prop in pager) {
      if (
        (pager.hasOwnProperty(prop) && pager[prop] === null) ||
        pager[prop] == ''
      ) {
        delete pager[prop];
      }
    }
  }

  navigate(id: number) {
    this.router.navigate([`/main/CompletedLessonDetail/${id}`]);
  }

  pageChanged(page: any) {
    this.pageNumber = page; // -1 * pageSize;
    this.pager.skipCount = (this.pageNumber - 1) * this.pager.maxResultCount;
    this.GetTeacherPurchasedLessons();
    this.pageNumber;
  }
  SearchFormPag() {
    this.pageNumber = 1; // -1 * pageSize;
    this.pager.skipCount = 0;
    this.pager = { ...this.pager, ...this.SearchForm.getRawValue() };

    this.GetTeacherPurchasedLessons();
    this.pageNumber;



  }

  protected onSubmit() {
    // this.submit = false;

    if (this.fc["dateFrom"].value && this.fc["dateTo"].value) {
      const fromStartDate = this.fc["dateFrom"].value;
      const toEndDate = this.fc["dateTo"].value;

      if (fromStartDate > toEndDate) {
        // this.notifier.showError('Start Date Should Be Before To End Date');
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Start Date Should Be Before To End Date",
        });
        // this.submit = true;
      } else {
        this.SearchFormPag();
      }
    } else {
      this.SearchFormPag();
    }
  }
}
