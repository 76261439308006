import { Component, OnInit } from '@angular/core';
import { CommonModule, NgFor, NgIf } from '@angular/common';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
// import { Modal } from 'flowbite';
import {
  FormControl,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import Swal from 'sweetalert2';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import {
  ILesson,
  IUnitLessos,
  ModelPostData,
} from 'src/app/shared/Model/teacher/lesson';

import { TeacherLessonService } from 'src/app/shared/Apis/Teacher/TeacherLesson/teacher-lesson.service';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { ModelBriefComponent } from 'src/app/shared/components/model-brief/model-brief.component';
import { OnlyNumbersWithDoubleDirective } from 'src/app/shared/Directive/only-numbers-with-double.directive';
import { OnlyNumbersNotToStarZeroDirective } from 'src/app/shared/Directive/only-numbers-not-to-star-zero.directive';
import { decimalNotToStarZero } from 'src/app/shared/core/appSetting';
import { SeoService } from 'src/app/shared/Apis/appsettings/seo.service';
import { LayoutService } from 'src/app/shared/core/layout.service/layout.service';

@Component({
  selector: 'app-manage-my-subject-lessons',
  standalone: true,
  imports: [
    CommonModule,
    NgIf,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    TooltipModule,
    OnlyNumbersWithDoubleDirective,
    OnlyNumbersNotToStarZeroDirective,
  ],
  templateUrl: './manage-my-subject-lessons.component.html',
  styleUrls: ['./manage-my-subject-lessons.component.scss'],
})
export class ManageMySubjectLessonsComponent implements OnInit {
  SearchInp: FormControl = new FormControl(null, Validators.required);
  protected teacherUnitLessons!: IUnitLessos[];
  protected decimalNotToStarZero: string = decimalNotToStarZero;

  private subjectSemesterYearId!: number;
  private TeacherSubjectAcademicSemesterYearId!: number;
  protected TeacherSubjectAcademic: any = {};
  protected modalRef?: BsModalRef;
  protected submit: boolean = true;
  protected _item?: ILesson;
  protected index!: number;

  constructor(
    private _TeacherLesson: TeacherLessonService,
    private translate: TranslateService,
    private _activatedRoute: ActivatedRoute,
    private modalService: BsModalService,
    private router: Router,    private seoService: SeoService,
    private layoutService: LayoutService,
  ) {}
  ngOnInit(): void {
    this.getQueryParam();
    this._TeacherLesson.flag$.subscribe((flag: boolean) => {
      // Do something based on the flag value
      this.GetTeacherLesson({
        subjectSemesterYearId: this.subjectSemesterYearId,
      });
    });
    this.seo();

  }
  // ILesson

  ChangeSearchInp() {
    if (this.SearchInp.value != null && this.SearchInp.value)
      this.GetTeacherLesson({
        subjectSemesterYearId: this.subjectSemesterYearId,
        lessonName: this.SearchInp.value ?? null,
      });
    else
      this.GetTeacherLesson({
        subjectSemesterYearId: this.subjectSemesterYearId,
      });
  }

  private GetTeacherLesson(ModelPostData: ModelPostData) {
    this._TeacherLesson
      .GetTeacherLesson(ModelPostData)
      .subscribe((_teacherUnitLessons) => {
        this.teacherUnitLessons = _teacherUnitLessons;
        this.TeacherSubjectAcademic = this.teacherUnitLessons[0];
      });
  }

  protected OnEdit(item: any) {
    item.isEdit = true;
  }

  protected validateForm(obj: any) {
    if (
      // obj.individualCost > 0 &&
      obj.individualDuration > 0 &&
      // obj.groupCost > 0 &&
      obj.groupDuration > 0
      //  &&
      // obj.minGroup > 0 &&
      // obj.maxGroup > 0
    ) {
      this.CreateOrUpdate(obj);

      return false;
    } else {
      Swal.fire({
        icon: 'warning',
        title: this.translate.instant(`The form must be non-empty or not zero`),
        showConfirmButton: true,
        timer: 1500,
      });
      return true;
    }
  }
  protected onCancel(item: any) {
    //  item = {...this._item };

    item.isEdit = false;
    this.GetTeacherLesson({
      subjectSemesterYearId: this.subjectSemesterYearId,
    });
    // this.GetTeacherLesson({subjectSemesterYearId:this.subjectSemesterYearId});
  }
  setItem(item: ILesson) {
    this._item = Object.assign({}, item);
    this._item.isEdit = false;
  }
  /**
   * Creates a new lesson if the provided item has an id of 0,
   * or updates an existing lesson if the id is non-zero.
   *
   * @param item The lesson object to create or update.
   *
   * Example usage:
   *
   * ```typescript
   * const lessonToCreateOrUpdate = { id: 0, /* other properties  };
   * CreateOrUpdate(lessonToCreateOrUpdate);
   *
   *
   * Note: The method assumes a non-zero id implies an update operation.
   *
   * @returns {void} This method doesn't return anything.'
   */
  protected CreateOrUpdate(item: any) {
    this.submit = false;

    //  const  message =item.id==0 ?  "Create Lesson" : "Update Lesson";
    const message = 'Update Lesson';
    item.teacherSubjectAcademicSemesterYearId =
      this.TeacherSubjectAcademicSemesterYearId;

    this._TeacherLesson.CreateOrUpdateTeacherLesson(item).subscribe(
      (res) => {
        if (res.success) {
          Swal.fire({
            icon: 'success',
            title: this.translate.instant(`${message} Success`),
            showConfirmButton: false,
            timer: 1500,
          });
          // this.GetTeacherLesson({subjectSemesterYearId:this.subjectSemesterYearId});
          item.isEdit = false;
          item.id = res.data.id;
        } else
          Swal.fire({
            icon: 'success',
            title: this.translate.instant(`${message} invalid`),
            showConfirmButton: false,
            timer: 1500,
          });
        this.submit = true;
        // this._item=
      },
      (err) => {
        this.submit = true;
      }
    );
  }

  protected openModalForHeacherBriefComponent(e: number, index: number) {
    const initialState: ModalOptions = {
      initialState: {
        teacherBrief: e,
        title: 'Set Offer',
      },
    };
    this.modalRef = this.modalService.show(ModelBriefComponent, initialState);
    this.modalRef.content.closeBtnName = 'Close';
    this.modalRef.setClass('modal-dialog modal-dialog-centered modal-xl');
  }

  navigate(id: number) {
    this.router.navigate([`/main/ManageMySubject/Lessons/Matrials/${id}`]);
  }
  private getQueryParam() {
    this._activatedRoute.params.subscribe((params) => {
      // Do something with the params
      this.TeacherSubjectAcademicSemesterYearId = JSON.parse(params['id']);
      this.subjectSemesterYearId = JSON.parse(params['subjectAcademicYearId']);
      if (this.subjectSemesterYearId)
        this.GetTeacherLesson({
          subjectSemesterYearId: this.subjectSemesterYearId,
        });
    });
  }

  saveChanges(items: any) {
    // Save changes logic here
   }
  //  seo ssr
private seo(){
  this.seoService.setMetaImage( 'assets/images/MrS-Cool/setting.png'   );
  const lang = this.layoutService.config.langu;
  this.seoService.loadTranslations(lang).subscribe(translations => {
    this.seoService.setTitle(translations.ManageMySubject.header.Title2);
    this.seoService.setHostUrlIndex();
    this.seoService.setMetaDescription(translations.Home.header.meta_description)
    this.seoService.setMetaKeywords(this.seoService.generateKeywords(translations.Home.header.meta_description ))


  });

}
}
