import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  TemplateRef,
} from '@angular/core';
import { CommonModule, NgIf } from '@angular/common';
import { BsModalRef, BsModalService, ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import Swal from 'sweetalert2';
import { SubjectInfoService } from 'src/app/shared/Apis/Teacher/SubjectInfo/subject-info.service';
import { LookupService } from 'src/app/shared/Apis/lookup/lookup.service';
import { Subscription } from 'rxjs';
import {
  SubjectInfo,
  TeacherSubjectAcademicSemeste,
} from 'src/app/shared/Model/teacher/subject-info';
import { IStatus } from 'src/app/shared/Model/Country/country';
import { Router, RouterLink } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { DisableWeritingArabicDirective } from 'src/app/shared/Directive/disable-weriting-arabic.directive';
import { OnlyNumbersWithDoubleDirective } from 'src/app/shared/Directive/only-numbers-with-double.directive';
import { OnlyNumbersNotToStarZeroDirective } from 'src/app/shared/Directive/only-numbers-not-to-star-zero.directive';
import { DisableWritingEnglishDirective } from 'src/app/shared/Directive/disable-weriting-english.directive';
import {  decimalNotToStarZero} from 'src/app/shared/core/appSetting';
import { MatExpansionModule } from '@angular/material/expansion';
import { SeoService } from 'src/app/shared/Apis/appsettings/seo.service';
import { LayoutService } from 'src/app/shared/core/layout.service/layout.service';
import { OnlyNumbersDirective } from 'src/app/shared/Directive/only-numbers.directive';
@Component({
  selector: 'app-manage-my-subjects',
  standalone: true,
  imports: [
    CommonModule,
    NgIf,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    TooltipModule,
    ModalModule,
    NgSelectModule,
     DisableWeritingArabicDirective,
    DisableWritingEnglishDirective,
    OnlyNumbersNotToStarZeroDirective,
    OnlyNumbersWithDoubleDirective,MatExpansionModule,OnlyNumbersDirective
  ],
  templateUrl: './manage-my-subjects.component.html',
  styleUrls: ['./manage-my-subjects.component.scss'],
})
export class ManageMySubjectsComponent implements OnInit {
  // Form groups and model properties
  protected insertForm!: FormGroup;
  protected EducationTypes!: SubjectInfo[];
  protected EducationLevel!: SubjectInfo[];
  protected AcademicYear!: SubjectInfo[];
  protected SubjectSemester!: SubjectInfo[];
  protected Subject?: SubjectInfo;
  protected TeacherSubjectAcademic!: TeacherSubjectAcademicSemeste[];
  protected subscription = new Subscription();
  submit: boolean = true;
  submitSearsh: boolean = true;
  modalRef?: BsModalRef;
  protected SearchForm!: FormGroup;
  protected EducationLevelSearch!: SubjectInfo[];
  protected AcademicYearSearch!: SubjectInfo[];
  protected SubjectSemesterSearch!: SubjectInfo[];
  protected StatusSearch!: IStatus[];
  public panelOpenState  = false;

  // Output event emitter
  @Output() ToDocuments: EventEmitter<boolean> = new EventEmitter<boolean>();

  // Property to store the ID of the document to be deleted
  protected TableDeletDocID!: number;

  // Constructor with dependency injection
  constructor(
    private _SubjectInfo: SubjectInfoService,
    private _Lookup: LookupService,
    private translate: TranslateService,
    private _fb: FormBuilder,
    private modalService: BsModalService,
    private router: Router,
    private seoService: SeoService,
    private layoutService: LayoutService,
  ) {}

  // Initialization method called on component initialization
  ngOnInit(): void {
    this.initiate();
    this.initiateSearch();
    this.GetEducationType();
    this.GetStatus();
    this.GetTeacherSubjectAcademic({});
    this.seo();

  }
  editKey:any
  startEdit(key: any, value: any): void {
    this.editKey = key;
    // this.editValue = value;
  }
  // Getters for form controls
  protected get fc() {
    return this.insertForm.controls;
  }

  protected get fcSearch() {
    return this.SearchForm.controls;
  }

  // Private method to initialize the search form
  private initiateSearch() {
    this.SearchForm = this._fb.group({
      educationTypeId: [''],
      educationLevelId: [''],
      academicYearId: '',
      subjectId: '',
      statusId: '',
    });
  }

  /**
   * Private method to initialize the insert form with optional values from a provided row (TeacherSubjectAcademicSemeste).
   * If a row is provided, sets up the form with values from the row for updating, and disables the corresponding form controls.
   * If no row is provided, initializes the form with default values and validation.
   *
   * @param {TeacherSubjectAcademicSemeste} [row] - The row containing data for pre-filling the form.
   * @memberof YourComponent
   */
  private initiate(row?: TeacherSubjectAcademicSemeste): void {
    // Initialize the form controls with default values and validation
    this.insertForm = this._fb.group({
      id: [row?.id || null],
      EducationTypeId: [row?.educationTypeId || '', Validators.required],
      EducationLevelId: [row?.educationLevelId || '', Validators.required],
      subjectSemesterYearId: [
        row?.subjectSemesterYearId || '',
        Validators.required,
      ],
      SubjectSemesterId: [row?.academicYearId || '', Validators.required],
      // GroupCost: [
      //   row?.groupCost || null,
      //   [Validators.required, Validators.pattern(decimalNotToStarZero)],
      // ],
      groupSessionCost: [
        row?.groupSessionCost || null,
        [Validators.required, Validators.pattern(decimalNotToStarZero)],
      ],
      IndividualCost: [ 0,
        ,
      ],
      // MinGroup: [
      //   row?.minGroup || null,
      //   [Validators.required, Validators.pattern(NumbersNotToStarZero)],
      // ],
      // MaxGroup: [
      //   row?.maxGroup || null,
      //   [Validators.required, Validators.pattern(NumbersNotToStarZero)],
      // ],
      teacherBrief: [row?.teacherBrief || null],
      TeacherBriefEn: [row?.teacherBriefEn || null],

      // When updating, disable the corresponding form controls to prevent modification
      EducationTypeIdUpdate: [
        { value: row?.educationTypeId || '', disabled: true },
      ],
      EducationLevelIdUpdate: [
        { value: row?.educationLevelId || '', disabled: true },
      ],
      subjectSemesterYearIdUpdate: [
        { value: row?.subjectSemesterYearId || '', disabled: true },
      ],
      SubjectSemesterIdUpdate: [
        { value: row?.academicYearId || '', disabled: true },
      ],
    });

    // If a row is provided, fetch additional select options
    if (row) this.getAllSelect(true);
  }

  // Used For AddTeacherSubjectAcademic
  /**
   * Fetches education types and sets the 'EducationTypes' property.
   * This method subscribes to the education type data from the Lookup service.
   */
  GetEducationType(): void {
    this._Lookup
      .GetEducationType()
      .subscribe((_EducationTypes) => (this.EducationTypes = _EducationTypes));
  }

  /**
   * Initiates a change in the education type for adding a teacher's subject academic.
   * Resets the 'EducationLevelId' form control and fetches education levels based on the selected education type.
   * This method is marked as protected, indicating it is intended for use within the class hierarchy.
   */
  protected ChangEducationType(): void {
    // Reset the 'EducationLevelId' form control when changing the education type
    this.fc['EducationLevelId'].setValue('');
    this.fc['subjectSemesterYearId'].setValue('');
    this.fc['SubjectSemesterId'].setValue('');
    this.AcademicYear = [];
    this.EducationLevel = [];
    this.SubjectSemester = [];

    // Fetch education levels based on the selected education type
    this.GetEducationLevel();
  }

  /**
   * Fetches education levels based on the selected education type for adding a teacher's subject academic.
   * Subscribes to the data from the Lookup service and assigns it to the 'EducationLevel' property.
   */
  GetEducationLevel(): void {
    // Check if an education type is selected before fetching education levels
    if (this.fc['EducationTypeId'].value) {
      // Subscribe to education levels data from the Lookup service
      this._Lookup
        .GetEducationLevelByEducationType(this.fc['EducationTypeId'].value)
        .subscribe(
          (_EducationLevel) => (this.EducationLevel = _EducationLevel)
        );
    } else {
      // If no education type is selected, reset the 'EducationLevel' property
      this.EducationLevel = [];
    }
  }

  /**
   * Initiates a change in the education level for adding a teacher's subject academic.
   * Resets the 'SubjectSemesterId' form control and fetches academic years based on the selected education level.
   * This method is marked as protected, indicating it is intended for use within the class hierarchy.
   */
  protected ChangEducationLevel(): void {
    // Reset the 'SubjectSemesterId' form control when changing the education level
    this.fc['SubjectSemesterId'].setValue('');

    // Fetch academic years based on the selected education level
    this.SelectedEducationLevel();
  }

  /**
   * Fetches academic years based on the selected education level for adding a teacher's subject academic.
   * Subscribes to the data from the Lookup service and assigns it to the 'AcademicYear' property.
   * This method is marked as private, indicating it is intended for use only within this class.
   */
  private SelectedEducationLevel(): void {
    // Check if an education level is selected before fetching academic years
    if (this.fc['EducationLevelId'].value) {
      // Subscribe to academic years data from the Lookup service
      this.subscription.add(
        this._Lookup
          .GetAcademicYearByeducationLevelId(this.fc['EducationLevelId'].value)
          .subscribe((_AcademicYear) => (this.AcademicYear = _AcademicYear))
      );
    } else {
      // If no education level is selected, reset the 'AcademicYear' property
      this.AcademicYear = [];
    }
  }

  /**
   * Initiates a change in the academic year for adding a teacher's subject academic.
   * Resets the 'subjectSemesterYearId' form control and fetches subject semesters based on the selected academic year.
   * This method is marked as protected, indicating it is intended for use within the class hierarchy.
   */
  protected ChangAcademicYear(): void {
    // Reset the 'subjectSemesterYearId' form control when changing the academic year
    this.fc['subjectSemesterYearId'].setValue('');

    // Fetch subject semesters based on the selected academic year
    this.SelectedAcademicYear();
  }

  /**
   * Fetches subject semesters based on the selected academic year for adding a teacher's subject academic.
   * Subscribes to the data from the Lookup service and assigns it to the 'SubjectSemester' property.
   * This method is marked as private, indicating it is intended for use only within this class.
   */
  private SelectedAcademicYear( getAll :boolean =false): void {
    // Check if both 'SubjectSemesterId' and 'SubjectSemesterYearId' have values before fetching subject semesters
    if (
      this.fc['SubjectSemesterId'].value &&
      this.fc['SubjectSemesterId'].value
    ) {
      // Subscribe to subject semesters data from the Lookup service
      this.subscription.add(
        this._Lookup
          .GetSubjectSemesterYearByAcademicYear(
            this.fc['SubjectSemesterId'].value, getAll
          )
          .subscribe((_SubjectSemester) => {
            this.SubjectSemester = _SubjectSemester;
            this.GetSubjectSelected();
          })
      );
    } else {
      // If one or both values are not selected, reset the 'SubjectSemester' property
      this.SubjectSemester = [];
    }
  }

  // End Used For AddTeacherSubjectAcademic

  /**
   * Handles the form submission for adding or updating a teacher's subject academic.
   * Checks if the form is valid, then either inserts new data or updates existing data.
   * Also fetches the teacher's subject academic information after a successful operation.
   */
  protected onSubmit(): void {
    if (this.insertForm.valid) {
      this.fc['TeacherBriefEn'].value?null : this.fc['TeacherBriefEn'].setValue(null)
      this.fc['teacherBrief'].value?null : this.fc['teacherBrief'].setValue(null)
      // if (
      //   JSON.parse(this.fc['MaxGroup'].value) >=
      //   JSON.parse(this.fc['MinGroup'].value)
      // ) {
        // Perform the submit logic here
        this.ClearSearch();
        this.fc['id'].value == null ? this.insertData() : this.UpdateSubject();
      }
    //    else
    //     Swal.fire({
    //       icon: 'warning',
    //       title: 'warning',
    //       text: `Minimum group "${this.fc['MinGroup'].value}" must be less than or equal to maximum group "${this.fc['MaxGroup'].value}.  `,
    //     });
    // }

    else {
      // Mark all form controls as touched to display validation errors
      this.insertForm.markAllAsTouched();
    }
  }

  // Start Used Table
  /**
   * Inserts new data for a teacher's subject academic.
   * Subscribes to the addition operation and triggers a series of actions upon success,
   * including fetching updated teacher subject academic information, resetting the form,
   * and resetting associated properties.
   * This method is marked as private, indicating it is intended for use only within this class.
   */
  private insertData(): void {
    this.submit = false;
    this._SubjectInfo
      .AddTeacherSubjectAcademic(this.insertForm.getRawValue())
      .subscribe(
        (res) => {
          if (res.success == true) {
            // Fetch updated teacher subject academic information
            this.SearchForm.reset();
            this.GetTeacherSubjectAcademic({});
            // Display a success message
            Swal.fire({
              icon: 'success',
              title: 'success',
              text: 'Add Succsessfully',
            });
            // Reset the form
            this.insertForm.reset();
            this.fc['IndividualCost'].setValue(0)
            this.ScrollTop();


            // Reset associated properties
            this.EducationLevel = [];
            this.AcademicYear = [];
            this.SubjectSemester = [];
          }
          this.submit = true;
        },
        (err) => {
          this.submit = true;
        }
      );
  }

  /**
   * Updates existing data for a teacher's subject academic.
   * Subscribes to the update operation and triggers a series of actions upon success,
   * including fetching updated teacher subject academic information, resetting the form,
   * and resetting associated properties.
   * This method is marked as private, indicating it is intended for use only within this class.
   */
  private UpdateSubject(): void {
    this.submit = false;
    this._SubjectInfo
      .UpdateTeacherSubjectAcademic(this.insertForm.getRawValue())
      .subscribe(
        (res) => {
          if (res.success == true) {
            // Fetch updated teacher subject academic information
            this.SearchForm.reset();
            this.GetTeacherSubjectAcademic({});
            // Display a success message
            Swal.fire({
              icon: 'success',
              title: 'success',
              text: 'Update Succsessfully',
            });
            // Reset the form
            this.insertForm.reset();
            this.fc['IndividualCost'].setValue(0)
            this.ScrollTop();

            // Reset associated properties
            this.EducationLevel = [];
            this.AcademicYear = [];
            this.SubjectSemester = [];
          }
          this.submit = true;
        },
        (err) => {
          this.submit = true;
        }
      );
  }

  /**
   * Fetches teacher's subject academic information based on the search criteria.
   * Removes properties with null or empty values from the search filter.
   * Subscribes to the data from the SubjectInfo service and assigns it to the 'TeacherSubjectAcademic' property.
   * This method is marked as private, indicating it is intended for use only within this class.
   */
  private GetTeacherSubjectAcademic(_filter: any): void {
    this.submitSearsh = false;

    const filter = _filter;
    // Remove properties with value null or empty
    Object.keys(filter).forEach(
      (key) =>
        (filter[key] === null && delete filter[key]) ||
        (filter[key] === '' && delete filter[key])
    );
    this.subscription.add(
      this._SubjectInfo.GetTeacherSubjectAcademic(filter).subscribe(
        (_TeacherSubjectAcademic) => {
          this.TeacherSubjectAcademic = _TeacherSubjectAcademic;
          this.submitSearsh = true;
        },
        (err) => {
          this.submitSearsh = true;
        }
      )
    );
  }

  /**
   * Deletes a teacher's subject academic record based on the given ID.
   * Subscribes to the deletion operation and triggers a series of actions upon success,
   * including fetching updated teacher subject academic information, resetting the form,
   * hiding the modal, and displaying a success message.
   *
   * @protected
   * @param {number} id - The ID of the teacher's subject academic record to be deleted.
   * @memberof YourComponent
   */
  protected DeleteTeacherSubjectAcademic(id: number): void {
    this.submit = false;

    this.subscription.add(
      this._SubjectInfo.DeleteTeacherSubjectAcademic(id).subscribe(
        (_TeacherSubjectAcademic) => {

          // Reset the form
          this.insertForm.reset();
          this.SearchForm.reset();
          // Fetch updated teacher subject academic information
          this.GetTeacherSubjectAcademic({});
          this.fc['IndividualCost'].setValue(0)
          // Hide the modal
          this.modalRef?.hide();

          // Display a success message
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: 'Deleted successfully',
          });
          this.submit = true;
        },
        (err) => {
          this.submit = true;
        }
      )
    );
  }
  // End Used Table

  // Search Used Table
  /**
   * Initiates a change in the education type for searching.
   * Resets the 'educationLevelId' form control and fetches education levels based on the selected education type.
   * This method is marked as protected, indicating it is intended for use within the class hierarchy.
   *
   * @protected
   * @memberof YourComponent
   */
  protected ChangEducationTypeSearch(): void {
    // Reset the 'educationLevelId' form control when changing the education type for searching
    this.fcSearch['educationLevelId'].setValue('');
    this.fcSearch['academicYearId'].setValue('');
    this.fcSearch['subjectId'].setValue('');
    this.AcademicYearSearch = [];
    this.EducationLevelSearch = [];
    this.SubjectSemesterSearch = [];
    // Fetch education levels based on the selected education type
    this.GetEducationLevelSearch();
  }

  /**
   * Fetches education levels based on the selected education type for searching.
   * Subscribes to the data from the Lookup service and assigns it to the 'EducationLevelSearch' property.
   *
   * @memberof YourComponent
   */
  GetEducationLevelSearch(): void {
    // Check if an education type is selected before fetching education levels
    if (this.fcSearch['educationTypeId'].value) {
      // Subscribe to education levels data from the Lookup service
      this._Lookup
        .GetEducationLevelByEducationType(
          this.fcSearch['educationTypeId'].value
        )
        .subscribe(
          (_EducationLevelSearch) =>
            (this.EducationLevelSearch = _EducationLevelSearch)
        );
    } else {
      // If no education type is selected, reset the 'EducationLevelSearch' property
      this.EducationLevelSearch = [];
    }
  }

  /**
   * Initiates a change in the education level for searching.
   * Resets the 'academicYearId' form control and fetches academic years based on the selected education level.
   * This method is marked as protected, indicating it is intended for use within the class hierarchy.
   *
   * @protected
   * @memberof YourComponent
   */
  protected ChangEducationLevelSearch(): void {
    // Reset the 'academicYearId' form control when changing the education level for searching
    this.fcSearch['academicYearId'].setValue('');
    this.fcSearch['subjectId'].setValue('');
    this.AcademicYearSearch = [];
    this.SubjectSemesterSearch = [];

    // Fetch academic years based on the selected education level
    this.SelectedEducationLevelSearch();
  }

  /**
   * Fetches academic years based on the selected education level for searching.
   * Subscribes to the data from the Lookup service and assigns it to the 'AcademicYearSearch' property.
   *
   * @private
   * @memberof YourComponent
   */
  private SelectedEducationLevelSearch(): void {
    // Check if an education level is selected before fetching academic years
    if (this.fcSearch['educationLevelId'].value) {
      // Subscribe to academic years data from the Lookup service
      this.subscription.add(
        this._Lookup
          .GetAcademicYearByeducationLevelId(
            this.fcSearch['educationLevelId'].value,
          )
          .subscribe(
            (_AcademicYearSearch) =>
              (this.AcademicYearSearch = _AcademicYearSearch)
          )
      );
    } else {
      // If no education level is selected, reset the 'AcademicYearSearch' property
      this.AcademicYearSearch = [];
    }
  }

  /**
   * Initiates a change in the academic year for searching.
   * Resets the 'subjectId' form control and invokes the method to fetch subject semesters based on the selected academic year.
   * This method is marked as protected, indicating it is intended for use within the class hierarchy.
   *
   * @protected
   * @memberof YourComponent
   */
  protected ChangAcademicYearSearch(): void {
    // Reset the 'subjectId' form control when changing the academic year for searching
    this.fcSearch['subjectId'].setValue('');

    // Fetch and set subject semesters based on the selected academic year
    this.SelectedAcademicYearSearch();
  }

  /**
   * Gets subject semesters based on the selected academic year for searching.
   * Subscribes to the data from the Lookup service and assigns it to the 'SubjectSemesterSearch' property.
   *
   * @private
   * @memberof YourComponent
   */
  private SelectedAcademicYearSearch(): void {
    // Check if an academic year is selected before fetching subject semesters
    if (this.fcSearch['academicYearId'].value) {
      // Subscribe to subject semesters data from the Lookup service
      this.subscription.add(
        this._Lookup
          .GetSubjectSemesterYearByAcademicYear(
            this.fcSearch['academicYearId'].value,true
          )
          .subscribe(
            (_SubjectSemesterSearch) =>
              (this.SubjectSemesterSearch = _SubjectSemesterSearch)
          )
      );
    } else {
      // If no academic year is selected, reset the 'SubjectSemesterSearch' property
      this.SubjectSemesterSearch = [];
    }
  }

  /**
   * Retrieves and sets the status options for searching.
   * This method subscribes to the status data from the Lookup service and assigns it to the 'StatusSearch' property.
   *
   * @memberof YourComponent
   */
  GetStatus(): void {
    // Fetch status options for searching from the Lookup service
    this._Lookup
      .GetStatus()
      .subscribe((_Status) => (this.StatusSearch = _Status));
  }
  /**
   * Initiates a change in the search input, triggering the retrieval of teacher's subject academic information.
   * This method is marked as protected, indicating it is intended for use within the class hierarchy.
   *
   * @protected
   * @memberof YourComponent
   */
  protected ChangeSearchInp(): void {
    // Fetch teacher's subject academic information based on the updated search input
    this.GetTeacherSubjectAcademic(this.SearchForm.getRawValue());
  }

  /**
   * Opens a modal dialog using the specified template.
   *
   * @param {TemplateRef<any>} template - The template reference defining the content of the modal.
   * @memberof YourComponent
   */
  protected openModal(template: TemplateRef<any>): void {
    // Set the modal reference using the provided template
    this.modalRef = this.modalService.show(template);
  }

  /**
   * Clears the form by resetting all form controls to their initial state.
   * This method is typically used to reset the form after a submission or when needed.
   *
   * @memberof ManageMySubjectsComponent
   */
  Clear(): void {
    // Reset the form to clear all form controls
    this.insertForm.reset();
    this.fc['IndividualCost'].setValue(0)
    this. EducationLevel=[];
    this. AcademicYear=[];
    this. SubjectSemester=[];
  }

  ClearSearch(): void {
    // Reset the form to clear all form controls
    this.SearchForm.reset();
    this. EducationLevelSearch=[];
    this. AcademicYearSearch=[];
    this. SubjectSemesterSearch=[];
  }
  /**
   * Initiates the editing process for a teacher's subject in a specific academic semester.
   * Performs necessary initialization steps, checks for required form control values,
   * logs a message, and scrolls the window to the top.
   *
   * @param {TeacherSubjectAcademicSemeste} item - The item representing the teacher's subject in a specific academic semester.
   * @memberof ManageMySubjectsComponent
   */
  Edit(item: TeacherSubjectAcademicSemeste): void {
    // Initialize the editing process
    this.initiate(item);

    // Check for required form control values before proceeding
    if (
      this.fc['EducationTypeId'].value &&
      this.fc['SubjectSemesterId'].value &&
      this.fc['subjectSemesterYearId'].value &&
      this.fc['EducationLevelId'].value
    ) {
      // Perform additional actions or uncomment the following line to fetch and initialize select options
      // this.getAllSelect();
    }

    // Scroll the window to the bottom
if (typeof window !== 'undefined') {
  window.scrollTo(0, document.body.scrollHeight);
}

  }

  /**
   * Retrieves and initializes various select options required for the component.
   * Invokes methods to fetch education levels and set selected values for education level,
   * academic year, and potentially other select elements.
   * Note: The commented-out line 'this.SelectTruckType();' suggests a possible addition for future implementation.
   *
   * @private
   * @memberof ManageMySubjectsComponent
   */
  private getAllSelect(getAll:boolean =false): void {
    // Fetch and populate education level options
    this.GetEducationLevel();

    // Set the selected education level based on certain criteria
    this.SelectedEducationLevel();

    // Set the selected academic year based on certain criteria
    this.SelectedAcademicYear(getAll);
  }
  /**
   * Navigates to the detailed view of a subject's lessons within the 'Manage My Subjects' component.
   * This method is marked as protected to indicate that it is intended for use within the class hierarchy.
   *
   * @protected
   * @param {*} item - The item containing information about the subject, including 'id' and 'subjectSemesterYearId'.
   *                   It is expected that 'item' has properties 'id' and 'subjectSemesterYearId'.
   * @memberof ManageMySubjectsComponent
   */
  protected navigate(item: any) {
    if(    item.groupSessionCost > 0)
      this.router.navigate([
        `/main/ManageMySubjectLessons/${item.id}/${item.subjectSemesterYearId}`,
      ]);
      else

    Swal.fire({
      icon: 'warning',
      title: this.translate.instant(`groupSessionPriceRequired`),
      showConfirmButton: true,
      timer: 2500,
    });
  }

  GetSubjectSelected() {
    if (this.fc['subjectSemesterYearId'].value) {
      this.Subject = this.SubjectSemester.find(
        (s) => s.id === this.fc['subjectSemesterYearId'].value
      );
    }
  }

  ScrollBottom(){
  // Scroll the window to the bottom
if (typeof window !== 'undefined') {
  window.scrollTo(0, document.body.scrollHeight);
}
}

  ScrollTop(){
  // Scroll the window to the top
  if (typeof window !== 'undefined') {
    window.scroll(0, 0);
  }
}
//  seo ssr
private seo(){
  this.seoService.setMetaImage( 'assets/images/MrS-Cool/setting.png'   );
  const lang = this.layoutService.config.langu;
  this.seoService.loadTranslations(lang).subscribe(translations => {
    this.seoService.setTitle(translations.ManageMySubjects.header.Title3);
    this.seoService.setHostUrlIndex();
    this.seoService.setMetaDescription(translations.Home.header.meta_description)
    this.seoService.setMetaKeywords(this.seoService.generateKeywords(translations.Home.header.meta_description ))


  });

}
}


