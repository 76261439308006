<div class="container-fluid h-100">
  <h4 class="dark:text-white  Lama-bold">{{ "FinanceTeacher.header.Finance" | translate }}</h4>

  <p class="mb-0 mt-3 text-left rtl:text-end   Notice" style="color: red">
    {{ "Notice" | translate }} : {{ "lesson_schedule_time" | translate }}

  <span class="mx-2"> {{ serverTime.slice(0, 16) }}</span>
</p>
  <div class="grid gap-2   lg:grid-cols-4 mt-2">


    <div
      class="card mt-2 mx-2 p-3 border-0 bg-[#ffffff] dark:bg-[#1a202c] BalancCard"
    >
      <label class="currentBalanceTxt  Lama-bold text-[#1B2651] dark:text-white"
        >
          {{ "FinanceTeacher.balanceCard.totalPurchases" | translate }}
        </label
      >
      <div class="mt-2 flex h-full justify-end align-items-end">
    <div class="  pa50">
          <h1  class="BalancNumb text-[#1B2651] dark:text-white"> {{TotalFinance?.totalPurchases}} {{ "FinanceTeacher.balanceCard.LE" | translate }}</h1>
        </div>
      </div>
    </div>

    <div *ngIf="TotalFinance"
      class="card mt-2 mx-2 p-3 border-0 bg-[#ffffff] dark:bg-[#1a202c] BalancCard"
    >
      <label class="currentBalanceTxt  Lama-bold text-[#1B2651] dark:text-white">

          {{ "FinanceTeacher.balanceCard.CurrentBalance" | translate }}
        </label
      >
      <div class="mt-2 flex h-full justify-end align-items-end">
        <div class="  pa50">
          <h1 class="BalancNumb text-[#1B2651] dark:text-white">
            {{TotalFinance?.totalDue}} {{ "FinanceTeacher.balanceCard.LE" | translate }}
          </h1>
        </div>
      </div>
    </div>

    <div
      class="card mt-2 mx-2 p-3 border-0 bg-[#ffffff] dark:bg-[#1a202c] BalancCard"
    >
      <label class="currentBalanceTxt  Lama-bold text-[#1B2651] dark:text-white"
        >
          {{ "FinanceTeacher.balanceCard.YouEarned" | translate }}
        </label
      >
      <div class="mt-2 flex h-full justify-end align-items-end">
    <div class="  pa50">
          <h1  class="BalancNumb text-[#39B002] dark:text-white"> {{TotalFinance?.totalIncome}} {{ "FinanceTeacher.balanceCard.LE" | translate }}</h1>
        </div>
      </div>
    </div>
    <div *ngIf="TotalFinance"
    class="card mt-2 mx-2 p-3 border-0 bg-[#ffffff] dark:bg-[#1a202c] BalancCard"
  >
    <label class="currentBalanceTxt  Lama-bold text-[#1B2651] dark:text-white">

        {{ "FinanceTeacher.balanceCard.remaining" | translate }}
      </label
    >
    <div class="mt-2 flex h-full justify-end align-items-end">
      <div class="  pa50">
        <h1 class="BalancNumb text-[#1B2651] dark:text-white">
          {{TotalFinance?.remaining}} {{ "FinanceTeacher.balanceCard.LE" | translate }}
        </h1>
      </div>
    </div>
  </div>

  </div>
  <div class="grid gap-2 mb-4 lg:grid-cols-3 mt-3">


    <div
      class="card mt-2 mx-2 p-3 border-0 bg-[#ffffff] dark:bg-[#1a202c] BalancCard"
    >
      <label class="currentBalanceTxt  Lama-bold text-[#1B2651] dark:text-white">

          {{ "FinanceTeacher.balanceCard.totalAbsence" | translate }}
        </label
      >
      <div class="mt-2 flex h-full justify-end align-items-end">
            <div class="  pa50">
          <h1 class="BalancNumb text-[red] dark:text-white"> {{TotalFinance?.totalTeacherNotattend}} {{ "FinanceTeacher.balanceCard.LE" | translate }}</h1>
        </div>

      </div>
    </div>
    <div
      class="card mt-2 mx-2 p-3 border-0 bg-[#ffffff] dark:bg-[#1a202c] BalancCard"
    >
      <label class="currentBalanceTxt  Lama-bold text-[#1B2651] dark:text-white"
        >
          {{ "FinanceTeacher.balanceCard.totalStudentNotattend" | translate }}
        </label
      >
      <div class="mt-2 flex h-full justify-end align-items-end">
    <div class="  pa50">
          <h1  class="BalancNumb text-[red] dark:text-white"> {{TotalFinance?.totalStudentNotattend}} {{ "FinanceTeacher.balanceCard.LE" | translate }}</h1>
        </div>
      </div>
    </div>

    <div
    class="card mt-2 mx-2 p-3 border-0 bg-[#ffffff] dark:bg-[#1a202c] BalancCard"
  >
    <label class="currentBalanceTxt  Lama-bold text-[#1B2651] dark:text-white">

        {{ "FinanceTeacher.balanceCard.totalCanceled" | translate }}
      </label
    >
    <div class="mt-2 flex h-full justify-end align-items-end">
  <div class="  pa50">
        <h1 class="BalancNumb text-[red] dark:text-white"> {{TotalFinance?.totalCanceled}} {{ "FinanceTeacher.balanceCard.LE" | translate }}</h1>
      </div>

    </div>
  </div>
  </div>

  <app-teacher-purchased-lessons></app-teacher-purchased-lessons>
  <br>
  <app-teacher-purchased-subjects></app-teacher-purchased-subjects>


</div>
